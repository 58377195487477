import './workingArea.scss';

import React, { useState, useEffect, useRef } from 'react';
import { Category, Field } from '../../components/properties/property.data';
import PropertyForm from '../../components/properties/property.form';
import WorkingAreaEditor, { TotalWorkingAreaSize } from './workingArea.areaEditor';
import WorkingAreaUtil from './util/workingArea.util';
import { IArea, IWorkingArea } from '../../data/workingArea/workingArea.data';
import { INavigation } from '../../data/navigation/navigationType';
import { useLocation } from 'react-router-dom';
import PageHeaderComponent from '../../components/pages/header/page.header.component';
import { ISettings, ISettingsInput } from '../../data/settings/settingsData';
import BindManager from '../../helpers/bindHelper';
import { Value, Vector2 } from '../../data/utils/vectorsData';
import { editSettings } from '../../store/settings.store';
import { store, useAppDispatch } from '../../store/store';
import { eHeaderButtonVisibility } from '../../components/header/header-button/header.button.visibility.types';
import { ClickEvent } from 'devextreme/ui/button';
import TabPanelComponent from '../../components/tab-panel/tab.panel.component';
import { WorkingAreaTranslates } from 'translator/pages/workingarea.translate';
import { useTranslator } from 'contexts/translator.context';
import { Utils } from 'utils/utils';
import { addEventFormChangeField } from 'store/form.change.store';
import { ChangesPropagationLevel } from 'data/form/formData';

const { Title } = WorkingAreaTranslates();

export const workingAreaSection = "WorkingArea";

const headerButtonsVisibility: eHeaderButtonVisibility[] = [eHeaderButtonVisibility.ADD, eHeaderButtonVisibility.COPY, eHeaderButtonVisibility.DELETE, eHeaderButtonVisibility.MOVE_DOWN, eHeaderButtonVisibility.MOVE_UP]

const WorkingArea: React.FC = () => {
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const [totalWorkingAreaSize, setTotalWorkingAreaSize] = useState<TotalWorkingAreaSize>({ screenWidth: 0, screenHeight: 0, width: 0, height: 0 });
  const [workingAreas, setWorkingAreas] = useState<IWorkingArea[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [fields, setFields] = useState<Field[]>([]);
  const [areas, setAreas] = useState<IArea[]>([]);
  const [allowBindAssign, setAllowBindAssign] = useState(true);
  const currentSettings = useRef<ISettings>();
  const translatorHelper = useTranslator();
  const bindManager = new BindManager();
  const resizeEvent = new Event('resize');
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);

  useEffect(() => {
    currentSettings.current = undefined;
    setTotalWorkingAreaSize({ screenWidth: 0, screenHeight: 0, width: 0, height: 0 })
    setAllowBindAssign(true);
    setFields([]);
    setCategories([]);
    setAreas([]);
    setWorkingAreas([]);

    const navigation = state.pageNavigation as INavigation;

    if (navigation !== null && navigation.sectionTemplate?.pageSection === workingAreaSection) {
      const formFields = JSON.parse(JSON.stringify(navigation.sectionTemplate));
      const translationPromise = translatorHelper.getTranslatedFieldsAndCategories(formFields.properties, formFields.categories);

      setFields(translationPromise.translationFields);
      setCategories(translationPromise.translationCategories);
    }
  }, [state.pageNavigation, translatorHelper]);

  const onWorkingAreaClicked = (id: string) => {
    workingAreaSelected(id, true);
  }

  const workingAreaSelected = (id: string, onTabChange?: boolean) => {
    let getWorkingArea = workingAreas.find(f => f.id === id);

    let updatedFields: Field[] = [];
    for (var field of fields) {
      if (onTabChange)
        field.isVisible = false;

      updatedFields.push({
        ...field,
      })
    }

    bindManager.assignBinds(updatedFields, getWorkingArea);

    setAreas(prevState => {
      let newState = prevState?.map(obj => {
        if (obj.id === getWorkingArea?.id) {
          return {
            ...obj,
            selected: obj.selected === undefined ? true : !obj.selected
          };
        }
        return {
          ...obj,
          selected: false
        };
      });
      return newState;
    });

    setFields(updatedFields);
  }

  const onAddNewWorkingArea = async () => {
    const id = Utils.generateGUID();
    addNewWorkingArea(id);
    addNewRectArea(id);
    dispatch(addEventFormChangeField());
  }

  const onDeleteWorkingArea = async () => {
    let selectedArea = getSelectedArea();
    if (selectedArea !== undefined) {
      let newAreas = areas.filter(a => a.id !== selectedArea?.id);
      newAreas = reorderAreas(newAreas);

      const newWorkingAreas = workingAreas.filter(a => a.id !== selectedArea?.id);

      const settings = JSON.parse(JSON.stringify(currentSettings.current!));
      settings.machineSettings.workingAreas?.splice(0, settings.machineSettings.workingAreas.length);
      settings.machineSettings.workingAreas?.push(...newWorkingAreas);
      currentSettings.current! = settings;

      propagateChanges(currentSettings.current!);

      setAreas(newAreas);
      setWorkingAreas(newWorkingAreas);
    }
  }

  const onDuplicateWorkingArea = async () => {
    let selectedArea = getSelectedArea();

    if (selectedArea !== undefined) {
      const duplicatedId = Utils.generateGUID();

      let duplicateArea: IArea = {
        id: duplicatedId,
        identifier: selectedArea.identifier,
        name: selectedArea.name,
        order: workingAreas.length,
        height: selectedArea.height,
        width: selectedArea.width,
        offsetX: selectedArea.offsetX,
        offsetY: selectedArea.offsetY,
        screenHeight: selectedArea.screenHeight,
        screenWidth: selectedArea.screenWidth,
        selected: false
      };

      let selectedWorkingArea = workingAreas.find(f => f.id === selectedArea?.id);
      let duplicateWorkingArea: IWorkingArea = {
        id: duplicatedId,
        identifier: duplicateArea.identifier,
        name: `Campo_${duplicateArea.identifier}`,
        location: selectedWorkingArea?.location,
        offset: selectedWorkingArea?.offset
      }

      const settings = JSON.parse(JSON.stringify(currentSettings.current!));
      settings.machineSettings.workingAreas?.push(duplicateWorkingArea);
      currentSettings.current! = settings;

      propagateChanges(currentSettings.current!);

      setAreas(previousState => [...previousState, duplicateArea]);
      setWorkingAreas(previousState => [...previousState, duplicateWorkingArea]);
    }
  }

  const onChangeOrder = async (upperOrder: boolean) => {
    let selectedArea = getSelectedArea();
    if (selectedArea !== undefined) {
      let otherAreas = areas.filter(m => m.id !== selectedArea?.id);

      if (otherAreas.length === 0)
        return;

      const newOrder = upperOrder ? selectedArea.order - 1 : selectedArea.order + 1;

      selectedArea.order = newOrder;

      const updatedAreas = areas.map(obj => {
        if (obj.id !== selectedArea?.id) {
          if ((upperOrder && obj.order >= newOrder) || (!upperOrder && obj.order <= newOrder)) {
            return {
              ...obj,
              order: upperOrder ? obj.order + 1 : obj.order - 1
            };
          }
        }
        return obj;
      });

      const reorderedAreas = reorderAreas(updatedAreas);

      setAreas(reorderedAreas);

      const newWorkingAreas = reorderedAreas.map(area => {
        const workingArea = workingAreas.find(wa => wa.id === area.id);
        return workingArea ? { ...workingArea, order: area.order } : null;
      }).filter(Boolean) as IWorkingArea[];

      const settings = JSON.parse(JSON.stringify(currentSettings.current!));
      settings.machineSettings.workingAreas = newWorkingAreas;
      currentSettings.current! = settings;

      propagateChanges(currentSettings.current!);

      setWorkingAreas(newWorkingAreas);
    }
  };

  const reorderAreas = (filteredAreas: IArea[]) => {
    filteredAreas.sort((a, b) => a.order - b.order);

    for (let i = 0; i < filteredAreas.length; i++) {
      filteredAreas[i].order = i;
    }

    return filteredAreas;
  };

  const addNewWorkingArea = async (id: string, workingAreaSize?: TotalWorkingAreaSize) => {
    let newWorkingArea: IWorkingArea = {};
    let workingAreaLetter = getWorkingAreaLetter(workingAreas.length);
    newWorkingArea.id = id;
    newWorkingArea.identifier = workingAreaLetter;
    newWorkingArea.name = `Campo_${workingAreaLetter}`;
    newWorkingArea.width = new Value(workingAreaSize?.width ?? totalWorkingAreaSize.width);
    newWorkingArea.height = new Value(workingAreaSize?.height ?? totalWorkingAreaSize.height);

    const settings = JSON.parse(JSON.stringify(currentSettings.current!));
    settings?.machineSettings.workingAreas?.push(newWorkingArea);
    currentSettings.current! = settings;

    propagateChanges(currentSettings.current!);

    setWorkingAreas(previousState => [...previousState, newWorkingArea]);
  }

  const getWorkingAreaLetter = (atIndex: number): string => {
    let workingAreaLetter = WorkingAreaUtil.getAlphabetLetter(atIndex);
    if (workingAreas.some(s => s.identifier === workingAreaLetter))
      return getWorkingAreaLetter(atIndex + 1);

    return workingAreaLetter;
  }

  const onInitializeEditorSize = (workingAreaSize: TotalWorkingAreaSize) => {
    setTotalWorkingAreaSize(workingAreaSize);
  }

  const addNewRectArea = async (id: string, workingAreaSize?: TotalWorkingAreaSize) => {
    const letter = getWorkingAreaLetter(workingAreas.length);

    let newArea: IArea = {
      id: id,
      identifier: letter,
      name: letter,
      order: workingAreas.length,
      width: workingAreaSize?.width ?? totalWorkingAreaSize.width,
      height: workingAreaSize?.height ?? totalWorkingAreaSize.height,
      screenWidth: workingAreaSize?.screenWidth ?? totalWorkingAreaSize.screenWidth,
      screenHeight: workingAreaSize?.screenHeight ?? totalWorkingAreaSize.screenHeight,
      offsetX: 0,
      offsetY: 0,
      selected: areas?.length === 0,
    };
    setAreas(previousState => [...previousState, newArea]);
  };

  const updateRectArea = async (updatedArea: IArea) => {
    setAreas(prevState => {
      let newState = prevState?.map(obj => {
        if (obj.id === updatedArea.id) {
          return updatedArea;
        }
        return obj;
      });
      return newState;
    });
  };

  const onSelectArea = (idArea: string) => {
    workingAreaSelected(idArea);
  }

  const updateAreaValues = (updatedArea: IWorkingArea) => {
    setWorkingAreas(prevState => {
      let newState = prevState?.map(obj => {
        if (obj.id === updatedArea.id) {
          return updatedArea;
        }
        return obj;
      });
      return newState;
    });
  }

  const onFieldChanged = async (fieldName?: string, fieldValue?: any) => {
    if (!fieldName || !fieldValue) return;

    let selectedArea = getSelectedArea();
    let getWorkingArea = JSON.parse(JSON.stringify(workingAreas.find(f => f.id === selectedArea?.id))) as IWorkingArea;

    if (!selectedArea) return;

    switch (fieldName) {
      case 'WorkingAreaWidth':
        if (+fieldValue > +totalWorkingAreaSize.width) fieldValue = totalWorkingAreaSize.width;

        selectedArea.width = +fieldValue;
        if (getWorkingArea !== undefined)
          getWorkingArea.width = new Value(+fieldValue);
        break;
      case 'WorkingAreaHeight':
        if (+fieldValue > +totalWorkingAreaSize.height) fieldValue = totalWorkingAreaSize.height;

        selectedArea.height = +fieldValue;
        if (getWorkingArea !== undefined)
          getWorkingArea.height = new Value(+fieldValue);
        break;
      case 'WorkingAreaOffsetX':
        if (getWorkingArea !== undefined) {
          let sumValues = (+fieldValue + +(getWorkingArea?.location?.x.value ?? 0) + +(getWorkingArea?.width?.value ?? 0));
          if (sumValues > +totalWorkingAreaSize.width) fieldValue = getWorkingArea.offset?.x.value;
          getWorkingArea.offset = new Vector2(+fieldValue, getWorkingArea.offset?.y.value);
        }

        selectedArea.offsetX = WorkingAreaUtil.translateOffsetX(totalWorkingAreaSize, fieldValue, getWorkingArea?.location?.x.value ?? 0);
        break;
      case 'WorkingAreaOffsetY':
        if (getWorkingArea !== undefined) {
          let sumValues = (+fieldValue + +(getWorkingArea?.location?.y.value ?? 0) + +(getWorkingArea?.height?.value ?? 0));
          if (sumValues > +totalWorkingAreaSize.height) fieldValue = getWorkingArea.offset?.y.value;
          getWorkingArea.offset = new Vector2(getWorkingArea.offset?.x.value, +fieldValue);
        }

        selectedArea.offsetY = WorkingAreaUtil.translateOffsetY(totalWorkingAreaSize, fieldValue, getWorkingArea?.location?.y.value ?? 0);
        break;
      case 'WorkingAreaLocationX':
        if (getWorkingArea !== undefined) {
          let sumValues = (+fieldValue + +(getWorkingArea?.offset?.x.value ?? 0) + +(getWorkingArea?.width?.value ?? 0));
          if (sumValues > +totalWorkingAreaSize.width) fieldValue = getWorkingArea.location?.x.value;
          getWorkingArea.location = new Vector2(+fieldValue, getWorkingArea.location?.y.value);
        }

        selectedArea.offsetX = WorkingAreaUtil.translateOffsetX(totalWorkingAreaSize, getWorkingArea?.offset?.x.value ?? 0, fieldValue);
        break;
      case 'WorkingAreaLocationY':
        if (getWorkingArea !== undefined) {
          let sumValues = (+fieldValue + +(getWorkingArea?.offset?.y.value ?? 0) + +(getWorkingArea?.height?.value ?? 0));
          if (sumValues > +totalWorkingAreaSize.height) fieldValue = getWorkingArea.location?.y.value;
          getWorkingArea.location = new Vector2(getWorkingArea.location?.x.value, +fieldValue);
        }

        selectedArea.offsetY = WorkingAreaUtil.translateOffsetY(totalWorkingAreaSize, getWorkingArea?.offset?.y.value ?? 0, fieldValue);
        break;

      case 'WorkingAreaIdentifier':
        selectedArea.identifier = fieldValue;
        break;

      default:
        break;
    }

    if (getWorkingArea !== undefined)
      updateAreaValues(getWorkingArea);

    window.dispatchEvent(resizeEvent);

    const [screenWidth, screenHeight] = WorkingAreaUtil.translateTotalAreaToWorkingAreaRect(totalWorkingAreaSize, selectedArea.width ?? 0, selectedArea.height ?? 0);
    selectedArea.screenWidth = screenWidth;
    selectedArea.screenHeight = screenHeight;

    window.dispatchEvent(resizeEvent);

    updateRectArea(selectedArea);
  }

  const getSelectedArea = () => {
    return areas?.find(f => f.selected === true);
  }

  const onUpdateSettings = () => {
    return currentSettings.current!;
  }

  const onEditorAssign = () => {
    areas.forEach(A => {
      let getWorkingArea = workingAreas.find(f => f.id === A?.id);

      if (getWorkingArea !== undefined) {
        updateAreaValues(getWorkingArea);

        window.dispatchEvent(resizeEvent);

        const offsetX = WorkingAreaUtil.translateOffsetX(totalWorkingAreaSize, getWorkingArea?.offset?.x.value ?? 0, getWorkingArea?.location?.x.value ?? 0);
        const offsetY = WorkingAreaUtil.translateOffsetY(totalWorkingAreaSize, getWorkingArea?.offset?.y.value ?? 0, getWorkingArea?.location?.y.value ?? 0);

        const [screenWidth, screenHeight] = WorkingAreaUtil.translateTotalAreaToWorkingAreaRect(totalWorkingAreaSize, A!.width ?? 0, A!.height ?? 0);
        A!.screenWidth = screenWidth;
        A!.screenHeight = screenHeight;
        A!.offsetX = offsetX;
        A!.offsetY = offsetY;

        window.dispatchEvent(resizeEvent);

        updateRectArea(A!);
      }
    });
  }

  const onBindAssign = (settings?: ISettings) => {
    if (allowBindAssign) {
      currentSettings.current = settings;

      const areas: IArea[] = [];

      settings?.machineSettings.workingAreas?.forEach(W => {
        let newArea: IArea = {
          id: W.id!,
          identifier: W.identifier!,
          name: W.name!,
          order: settings?.machineSettings.workingAreas?.indexOf(W)!,
          width: W?.width!.value > settings?.machineSettings.machineLength! ? settings?.machineSettings.machineLength! : W.width!.value ?? totalWorkingAreaSize.width,
          height: W?.height!.value > settings?.machineSettings.machineWidth! ? settings?.machineSettings.machineWidth! : W.height!.value ?? totalWorkingAreaSize.height,
          screenWidth: totalWorkingAreaSize.screenWidth,
          screenHeight: totalWorkingAreaSize.screenHeight,
          offsetX: W.offset?.x.value!,
          offsetY: W.offset?.y.value!,
          selected: false,
        };

        areas.push(newArea);
      });

      setAreas(areas);
      setWorkingAreas(settings?.machineSettings.workingAreas!);
      setAllowBindAssign(false);
    }

    let selectedArea = getSelectedArea();
    let getWorkingArea = workingAreas.find(f => f.id === selectedArea?.id);

    if (getWorkingArea === undefined) {
      workingAreaSelected(workingAreas![0]?.id!);
    }

    setPageLoaded(Array.isArray(workingAreas) && workingAreas.length > 0);
    return fields;
  }

  const onBindUpdate = (fieldValue?: any, obj?: Field, settings?: ISettings) => {
    if (!obj!.bind || fieldValue === undefined) return;

    currentSettings.current = settings;

    let selectedArea = getSelectedArea();
    let getWorkingArea = workingAreas.find(f => f.id === selectedArea?.id);

    let selectedWorkingArea = JSON.parse(JSON.stringify(getWorkingArea));

    bindManager.setValue(fieldValue, obj!, selectedWorkingArea);

    const availableWorkingAreas = settings?.machineSettings.workingAreas?.filter(W => W.id !== selectedWorkingArea?.id)
    availableWorkingAreas?.push(selectedWorkingArea!);

    settings?.machineSettings?.workingAreas?.splice(0, settings?.machineSettings.workingAreas.length);
    settings?.machineSettings?.workingAreas?.push(...availableWorkingAreas!);

    setWorkingAreas(availableWorkingAreas!);
  }

  function propagateChanges(sett: ISettings) {
    const storedSettings = store.getState().settingsDataState.settings ?? [];

    storedSettings.forEach(SS => {
      const settingsInput: ISettingsInput = {
        machineInfo: SS.machineInfo,
        settingsToUpdate: [],
        settingsToDelete: SS.settingsToDelete
      }

      if (SS.machineInfo.id === sett.machineInfoId) {
        SS.settingsToUpdate?.forEach(SU => {
          const settings: ISettings = {
            ...SU,
            machineSettings: {
              ...SU.machineSettings,
              workingAreas: [...sett.machineSettings.workingAreas!]
            }
          };
          settingsInput.settingsToUpdate?.push(settings);
        });
      }

      dispatch(editSettings(settingsInput));
    })
  }

  return (
    <React.Fragment>
      <PageHeaderComponent buttonHandlers={[
        [eHeaderButtonVisibility.ADD, onAddNewWorkingArea],
        [eHeaderButtonVisibility.DELETE, onDeleteWorkingArea],
        [eHeaderButtonVisibility.COPY, onDuplicateWorkingArea],
        [eHeaderButtonVisibility.MOVE_DOWN, (e: ClickEvent) => onChangeOrder(false)],
        [eHeaderButtonVisibility.MOVE_UP, (e: ClickEvent) => onChangeOrder(true)]]}
        headerButtonsVisibility={headerButtonsVisibility} title={Title} />
      <TabPanelComponent data={workingAreas} pageLoaded={pageLoaded} onTabChanged={(id: string) => onWorkingAreaClicked(id)} />

      <div className="wrap">
        <div className="fcenter">
          <WorkingAreaEditor areas={areas} fields={fields} onSelectArea={onSelectArea} onUpdateSettings={onUpdateSettings} onEditorAssign={onEditorAssign} onInitializeEditorSize={onInitializeEditorSize} />
        </div>
        <div>
          <div className="container">
            {/* <div className="div-middle">
              <WorkingAreaSelector workingAreas={workingAreas} areas={areas} onWorkingAreaClicked={onWorkingAreaClicked} />
            </div> */}
            <div className="div2-bottom">
              <PropertyForm changesPropagationLevel={ChangesPropagationLevel.MachineModel} categories={categories} fields={fields} onUpdateSettings={onUpdateSettings} onBindAssign={onBindAssign} onBindUpdate={onBindUpdate} onFieldChanged={onFieldChanged} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default WorkingArea;


