import PropertySelect from "./property.select";
import PropertyCheckbox from "./property.checkbox";
import PropertyText from "./property.text";
import PropertyEditor from "./property.editor";
import PropertyNumber from "./property.number";
import React from "react";

export default function PropertyInput({ formState, field, currentSettings, disableForm, onChange, treeViewRef }: any) {

    const getFieldType = () => {
        switch (field.type) {
            case 'Bool':
                return <PropertyCheckbox field={field} onChange={onChange} disableForm={disableForm} />;

            case 'DecimalNumber':
                return <PropertyNumber field={field} onChange={onChange} disableForm={disableForm} treeViewRef={treeViewRef} />;

            case 'Enum':
                return <PropertySelect field={field} onChange={onChange} disableForm={disableForm} />

            case 'Editor':
                return <PropertyEditor formState={formState} field={field} onChange={onChange} currentSettings={currentSettings} disableForm={disableForm} />

            case 'Text':
            case 'Guid':
            default:
                return <PropertyText field={field} onChange={onChange} disableForm={disableForm} treeViewRef={treeViewRef} />
        }
    }

    return (
        <>
            {getFieldType()}
        </>
    )
}