import './authorization.css'

import React, { useEffect, useState } from "react";
import Modal from "../base/modal/modal";
import useModalHook from "../../hooks/modal.hook";
import { SecurityTranslates } from "../../translator/components/security.translate";
import { PropTypes } from "data/modal/modal.proprieties";
import { TextBox } from 'devextreme-react';
import { ContentReadyEvent } from 'devextreme/ui/text_box';
import { runGetValidatePassword } from 'api/auth';
import { useNotification } from 'contexts/notification.context';

const { Password, InsertPassword, PasswordNotMatch } = SecurityTranslates();

export default function Authorization({ show, onClose, onButtonOk }: PropTypes) {

    const { setNotificationConfig } = useNotification();
    const { isOpen, toggle, open } = useModalHook();
    const [password, setPassword] = useState<string>();

    useEffect(() => {
        if (show)
            open();
    }, [show])

    const onReady = (e: ContentReadyEvent) => e.component.focus();

    const onChange = (value: string) => setPassword(value);

    const checkIsValid = async () => {
        const getGeneratePassword: Boolean = (await runGetValidatePassword(password ?? '')).isValid ?? false;
        if (!getGeneratePassword)
            setNotificationConfig({
                isVisible: true,
                message: PasswordNotMatch,
                type: 'error'
            });
        onButtonOk(getGeneratePassword);
    }

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} close={onClose} width="25%" height="5%" onButtonOk={() => checkIsValid()} modalHeader={Password}>
                <div className='modal-container'>
                    <div className="field-layout-text">{InsertPassword}</div>
                    <TextBox
                        className='field-style'
                        onContentReady={onReady}
                        onValueChange={onChange}
                        width={'100%'}
                        placeholder='******'
                    />
                </div>
            </Modal>
        </React.Fragment>
    );
}
