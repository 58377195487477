import addIcon from '../../../assets/images/icons/add.svg'
import copyIcon from '../../../assets/images/icons/copy.svg'
import deleteIcon from '../../../assets/images/icons/delete.svg'
import enableIcon from '../../../assets/images/icons/logout.svg'
import saveIcon from '../../../assets/images/icons/save.svg'
import settingsIcon from '../../../assets/images/icons/settings.svg'
import upIcon from '../../../assets/images/icons/up.svg'
import downIcon from '../../../assets/images/icons/down.svg'
import addIconHover from '../../../assets/images/icons/add_blue.svg'
import copyIconHover from '../../../assets/images/icons/copy_blue.svg'
import deleteIconHover from '../../../assets/images/icons/delete_blue.svg'
import enableIconHover from '../../../assets/images/icons/logout_blue.svg'
import saveIconHover from '../../../assets/images/icons/save_blue.svg'
import settingsIconHover from '../../../assets/images/icons/settings_blue.svg'
import upIconHover from '../../../assets/images/icons/up_blue.svg'
import downIconHover from '../../../assets/images/icons/down_blue.svg'
import deleteIconDisabled from '../../../assets/images/icons/delete_gray.svg'
import saveIconDisabled from '../../../assets/images/icons/save_gray.svg'
import { eHeaderButtonVisibility } from './header.button.visibility.types'
import { ButtonsTranslates } from 'translator/components/buttons.translate'

const { EnableSetups, Save, Copy, Add, Delete, Options, MoveDown, MoveUp } = ButtonsTranslates();

export const headerButtonConfig = [
    {
        items: [{
            text: EnableSetups,
            icon: enableIcon,
            hoverIcon: enableIconHover,
            disabled: false,
            onclick: () => { },
            visibilityType: eHeaderButtonVisibility.ENABLE_SETUPS
        }]
    },
    {
        items: [{
            text: Save,
            icon: saveIcon,
            hoverIcon: saveIconHover,
            disabledIcon: saveIconDisabled,
            disabled: false,
            onclick: () => { },
            visibilityType: eHeaderButtonVisibility.SAVE
        }]
    },

    {
        items: [{
            text: Copy,
            icon: copyIcon,
            hoverIcon: copyIconHover,
            disabled: false,
            onclick: () => { },
            visibilityType: eHeaderButtonVisibility.COPY
        }, {
            text: Add,
            icon: addIcon,
            hoverIcon: addIconHover,
            disabled: false,
            onclick: () => { },
            visibilityType: eHeaderButtonVisibility.ADD
        }, {
            text: Delete,
            icon: deleteIcon,
            hoverIcon: deleteIconHover,
            disabledIcon: deleteIconDisabled,
            disabled: false,
            onclick: () => { },
            visibilityType: eHeaderButtonVisibility.DELETE
        }]
    }, {
        items: [{
            text: Options,
            icon: settingsIcon,
            hoverIcon: settingsIconHover,
            disabled: false,
            onclick: () => { },
            visibilityType: eHeaderButtonVisibility.OPTIONS
        }]
    }, {
        items: [{
            text: MoveDown,
            icon: downIcon,
            hoverIcon: downIconHover,
            disabled: false,
            onclick: () => { },
            visibilityType: eHeaderButtonVisibility.MOVE_DOWN
        }]
    }, {
        items: [{
            text: MoveUp,
            icon: upIcon,
            hoverIcon: upIconHover,
            disabled: false,
            onclick: () => { },
            visibilityType: eHeaderButtonVisibility.MOVE_UP
        }]
    }, {
        items: [{
            text: 'Advanced Options',
            icon: settingsIcon,
            hoverIcon: settingsIconHover,
            disabled: false,
            onclick: () => { },
            visibilityType: eHeaderButtonVisibility.ADVANCED_OPTIONS
        }]
    },
];