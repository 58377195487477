export class Utils {

    static generateGUID(): string {
        const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
    }

    static isGuidMap = (obj: any): obj is { [key: string]: object } => {
        if (typeof obj !== 'object' || obj === null) {
            return false;
        }
        return Object.keys(obj).every(key =>
            typeof key === 'string' && typeof obj[key] === 'string'
        );
    }

    static arraysAreEqual(arr1: any[], arr2: any[]): boolean {

        if (arr1.length !== arr2.length) {
            return false;
        }

        const sortedArr1 = [...arr1].sort();
        const sortedArr2 = [...arr2].sort();

        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }

        return true;
    }
}