import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface PageDataStarted {
    startedPageSection: string;
    idSettings?: string;
    unsavedData?: any;
}

interface PageDataState {
    pagesStarted: PageDataStarted[]
}

const initialState: PageDataState = {
    pagesStarted: []
}

export const PageDataSlice = createSlice({
    name: 'pageData',
    initialState,
    reducers: {
        addStartedPage: (state, action: PayloadAction<PageDataStarted>) => {
            var data = state.pagesStarted.find(s => s.startedPageSection === action.payload.startedPageSection);
            if (!data)
                state.pagesStarted.push(JSON.parse(JSON.stringify(action.payload)))
        },
        editStartedPage: (state, action: PayloadAction<PageDataStarted>) => {
            var index = state.pagesStarted.findIndex(s => s.startedPageSection === action.payload.startedPageSection)
            if (index !== -1)
                state.pagesStarted[index] = action.payload;
        }
    }
})

export default PageDataSlice.reducer;
export const { addStartedPage, editStartedPage } = PageDataSlice.actions;