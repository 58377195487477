import { User } from "oidc-client-ts";
import { getApiAddress } from "./setupProxy";
import authService from '../cyncly-login/authService';

export async function apiGetUserData(accountId: string) {
  return authService.getUser().then((user) => {
    if (user?.access_token && !user?.expired) {
      return runGetUserData(user, accountId);
    } else {
      return {
        isOk: false,
        data: {
          accountID: 0,
          accessToken: ''
        }
      };
    }
  });
}

async function runGetUserData(user: User, accountId: string) {
  try {
    const response = await fetch(`${getApiAddress()}/Authentication/GetUserData`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        AccessToken: user.access_token,
        AccountID: accountId,
        IsInternalUser: user.profile["http://promobid.promob.com/identity/security/claims/promobinternaluser"] === 'True'
      })
    })
    return { data: await response.json() };
  }
  catch (ex) {
    return { data: null };
  }
}

export async function runGetGeneratePassword() {
  try {
    const response = await fetch(`${getApiAddress()}/Authentication/GeneratePassword`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
    return { password: await response.json() };
  }
  catch (ex) {
    return { password: null };
  }
}

export async function runGetValidatePassword(password: string) {
  try {
    const response = await fetch(`${getApiAddress()}/Authentication/ValidatePassword`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(password)
    })
    return { isValid: await response.json() };
  }
  catch (ex) {
    return { isValid: false };
  }
}