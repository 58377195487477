import { ISettings } from "../../data/settings/settingsData";
import { IHandler } from "../handlerManager";

export class FullLabelSettingsSelectedHandler implements IHandler {
    canHandle(bind: string, editorName: string): boolean {
        return bind === "SettingsDTO.LabelPrinterSettingsId";
    }

    handleTo(ogObject: object, object: object, value: any, bind: string) {
        if (value !== undefined) {
            return value;
        }
        return '';
    }

    handleBack(ogObject: object, object: object, value: any, bind: string) {
        const settings = object as ISettings;
        if (settings !== undefined) {
            settings.labelPrinterSettingsId = value as string;
        }

        return value;
    }
}