import { ISettings } from "../../data/settings/settingsData";
import { IHandler } from "../handlerManager";

export class SelectedInsertionProcessesHandler implements IHandler {
    canHandle(bind: string): boolean {
        return bind === "SettingsDTO.InsertionSettings.AvailableProcesses";
    }

    handleTo(ogObject: object, object: object, value: any, bind: string) {
        const insertionSettings = object as any;
        if (insertionSettings !== undefined) {
            if (insertionSettings!.availableProcesses?.length !== 0) {
                return insertionSettings!.availableProcesses;
            }
        }

        return value;
    }

    handleBack(ogObject: object, object: object, value: any, bind: string) {
        const insertionSettings = object as any;
        const settings = ogObject as ISettings;

        if (insertionSettings !== undefined) {
            insertionSettings.availableProcesses = value?.map((v: any) => {
                return v.enablingProcessOptionsType === 1
                    ? v.processOutput?.processId
                    : null;
            }).filter((v: any) => v !== null);

            settings.machineSettings.machiningDisabledProcesses = value?.map((v: any) => {
                return v.enablingProcessOptionsType === 0
                    ? v.processOutput?.processId
                    : null;
            }).filter((v: any) => v !== null);

            return insertionSettings.availableProcesses;
        }

        return value;
    }

}