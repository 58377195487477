import { addItemToDictionary, addValueToDictionary, removeItemFromDictionary, removeValueFromDictionary } from "../../components/properties/util/property.editor.util";
import { ISettings } from "../../data/settings/settingsData";
import { IHandler } from "../handlerManager";

export class SelectedMachiningProcessesHandler implements IHandler {
    canHandle(bind: string): boolean {
        return bind === "SettingsDTO.MachiningSettings.AvailableProcesses";
    }

    handleTo(ogObject: object, object: object, value: any, bind: string) {
        const machiningSettings = object as any;
        if (machiningSettings !== undefined) {
            if (machiningSettings!.availableProcesses?.length !== 0) {
                return machiningSettings!.availableProcesses;
            }
        }

        return value;
    }

    handleBack(ogObject: object, object: object, value: any, bind: string) {
        const machiningSettings = object as any;
        const settings = ogObject as ISettings;

        if (machiningSettings !== undefined) {
            machiningSettings.availableProcesses = value?.map((v: any) => {
                if (v.enablingProcessOptionsType !== 0) {
                    if (v.enablingProcessOptionsType === 2) {
                        const toolIDByProcessID = removeItemFromDictionary(settings.toolSettings?.toolIDByProcessID!, v.processOutput?.processId);
                        settings.toolSettings = {
                            ...settings.toolSettings,
                            toolIDByProcessID
                        };

                        const selectedToolOrProcessIDByOperationType = addValueToDictionary(settings.toolSettings?.selectedToolOrProcessIDByOperationType!, "ParametricMachiningDTO", v.processOutput?.processId);
                        settings.toolSettings = {
                            ...settings.toolSettings,
                            selectedToolOrProcessIDByOperationType
                        };
                    } else if (v.enablingProcessOptionsType === 4) {
                        const toolIDByProcessID = addItemToDictionary(settings.toolSettings?.toolIDByProcessID!, v.processOutput?.processId);
                        settings.toolSettings = {
                            ...settings.toolSettings,
                            toolIDByProcessID
                        };

                        const selectedToolOrProcessIDByOperationType = addValueToDictionary(settings.toolSettings?.selectedToolOrProcessIDByOperationType!, "ParametricMachiningDTO", v.processOutput?.processId);
                        settings.toolSettings = {
                            ...settings.toolSettings,
                            selectedToolOrProcessIDByOperationType
                        };
                    }

                    return v.processOutput?.processId;
                } else {
                    const toolIDByProcessID = removeItemFromDictionary(settings.toolSettings?.toolIDByProcessID!, v.processOutput?.processId);
                    settings.toolSettings = {
                        ...settings.toolSettings,
                        toolIDByProcessID
                    };

                    const selectedToolOrProcessIDByOperationType = removeValueFromDictionary(settings.toolSettings?.selectedToolOrProcessIDByOperationType!, "ParametricMachiningDTO", v.processOutput?.processId);
                    settings.toolSettings = {
                        ...settings.toolSettings,
                        selectedToolOrProcessIDByOperationType
                    };
                }

                return null;
            }).filter((v: any) => v !== undefined && v !== null);

            settings.machineSettings.machiningDisabledProcesses = value?.map((v: any) => {
                if (v.enablingProcessOptionsType === 0) {
                    return v.processOutput?.processId;
                }
                return null;
            }).filter((v: any) => v !== undefined && v !== null);

            return machiningSettings.availableProcesses;
        }

        return value;
    }

}