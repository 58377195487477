import { createSlice } from "@reduxjs/toolkit";

interface FormChangeDataState {
    changedValues: boolean
}

const initialState: FormChangeDataState = {
    changedValues: false
}

const beforeUnloadHandler = (event: Event) => {
    event.preventDefault();
    event.returnValue = true;
};

export const FormChangeDataSlice = createSlice({
    name: 'formChangeData',
    initialState,
    reducers: {
        addEventFormChangeField: (state) => {
            state.changedValues = true;
            window.addEventListener("beforeunload", beforeUnloadHandler);
        },
        clearEventFormChangeFields: (state) => {
            state.changedValues = false;
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }
})

export default FormChangeDataSlice.reducer;
export const { addEventFormChangeField, clearEventFormChangeFields } = FormChangeDataSlice.actions;