import './page.header.component.scss'

import React, { useEffect, useState } from 'react';
import PageSearchComponent from '../search/page.search.component';
import { eHeaderButtonVisibility } from '../../header/header-button/header.button.visibility.types';
import { ClickEvent } from 'devextreme/ui/button';
import Header from 'components/header/Header';
import { RequiredTranslates } from 'translator/components/required.translate';
import { store, useAppSelector } from 'store/store';

interface PageHeaderProps {
    title: string;
    headerButtonsVisibility: eHeaderButtonVisibility[]
    headerCustomComponents?: any;
    buttonHandlers?: [eHeaderButtonVisibility, (e: ClickEvent) => void][];
    pageData?: any[]
}

const { RequiredText } = RequiredTranslates();

const PageHeaderComponent: React.FC<PageHeaderProps> = ({ title, headerButtonsVisibility, headerCustomComponents, buttonHandlers, pageData }) => {

    const [hasInvalidFields, setHasInvalidFields] = useState<boolean>(false);
    const checkValidations = useAppSelector((state) => state.validationDataState.fields);

    useEffect(() => {
        setHasInvalidFields(store.getState().validationDataState.fields.some(s => !s.isValid));
    }, [checkValidations]);

    return (
        <React.Fragment>
            <div className='header-layout'>
                <div className={'header-text'}>
                    {title}
                </div>

                <PageSearchComponent />
            </div>
            <div className={headerCustomComponents !== undefined ? 'header-buttons-layout' : 'header-buttons-layout border-botom-layout'}>
                <Header buttonHandlers={buttonHandlers} headerButtonsVisibility={headerButtonsVisibility} pageData={pageData} />
                {hasInvalidFields && (
                    <div className='button-layout-warning'>
                        <div className='warning-icon' />
                        <div className='text-padding'> {RequiredText} </div>
                    </div>
                )}
            </div>
            <div className={headerCustomComponents !== undefined ? 'border-botom-layout-component' : ''}>
                {headerCustomComponents}
            </div>
        </React.Fragment>
    );
};

export default PageHeaderComponent;