import '../Header.scss';

import React, { useEffect, useState } from 'react';
import { HeaderButtonProps } from '../../../types';
import { ClickEvent } from 'devextreme/ui/button';
import { eHeaderButtonVisibility } from './header.button.visibility.types'
import { store, useAppSelector } from 'store/store';
import { useDispatch } from 'react-redux';
import { addEventFormChangeField } from 'store/form.change.store';

export const HeaderButton: React.FC<HeaderButtonProps> = (props) => {

    const [isHover, setIsHover] = useState<boolean>(false);
    const [hasInvalidFields, setHasInvalidFields] = useState<boolean>(false);
    const checkValidations = useAppSelector((state) => state.validationDataState.fields);
    const dispatch = useDispatch();

    useEffect(() => {
        setHasInvalidFields(store.getState().validationDataState.fields.some(s => !s.isValid));
    }, [checkValidations]);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (visibilityType !== eHeaderButtonVisibility.OPTIONS && visibilityType !== eHeaderButtonVisibility.SAVE && visibilityType !== eHeaderButtonVisibility.ADD)
            dispatch(addEventFormChangeField());

        props.buttonHandler(e as unknown as ClickEvent);
        if (props.customButtonEvents) {
            props.customButtonEvents();
        }
    };

    const { icon, hoverIcon, text, disabled, disabledIcon, visibilityType } = props;

    const isInvalidButton = () => (visibilityType === eHeaderButtonVisibility.SAVE && hasInvalidFields);

    return (
        <button className='button-layout' disabled={isInvalidButton() || disabled} onClick={handleClick} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <img className='button-icon' alt='headerbutton'
                src={isHover && !isInvalidButton() ? hoverIcon : isInvalidButton() || disabled ? disabledIcon : icon} />
            <div className={(visibilityType === eHeaderButtonVisibility.SAVE && hasInvalidFields) || disabled ? 'button-text-disabled' : 'button-text'}>
                {text}
            </div>
        </button>
    );
};
