import './property.css'

import Button from "devextreme-react/button";
import TextBox from "devextreme-react/text-box";
import React, { useEffect, useRef, useState } from "react";
import Editor from "../editor/editor";
import { editorTypeEnum } from "../../data/editor/editorType";
import { IFace } from "../../data/solidFaces/faces";
import FacesUtil from "../../data/solidFaces/util/faces.util";
import editIcon from '../../assets/images/icons/edit.svg';
import { InteractionUtil } from "./util/interaction.util";
import { store, useAppDispatch } from "../../store/store";
import { isEmptyGuid } from "./util/property.editor.util";
import { ITool } from "../../data/tools/toolData";
import { IProcessOutput } from "../../data/machine/templateData";
import { useTranslator } from "../../contexts/translator.context";
import {
    Validator,
    RequiredRule,
    ValidatorRef,
    CustomRule
} from 'devextreme-react/validator';
import { addValidation, removeValidation } from 'store/validation.store';
import { fieldConditions, IDataByConditionRules, operatorCondition } from 'components/editor/editor.interfaces';

interface PropertyEditorState {
    editorValue: any;
}

export default function PropertyEditor({ formState, field, currentSettings, disableForm, onChange }: any) {

    const dispatch = useAppDispatch();
    const translatorHelper = useTranslator();
    const storeState = store.getState();
    const getUser = storeState.userDataState.user;
    const [isEditorOpen, setEditorOpen] = useState(false);
    const validatorRef = useRef<ValidatorRef>(null);
    const toolRegex = /^(?!.*:\s*$).*$/;

    const requiredFieldsByOtherConditions = [editorTypeEnum.SelectSlotToolsByProcesses, editorTypeEnum.SelectMachiningToolsByProcesses, editorTypeEnum.SelectInsertionProcesses];

    useEffect(() => {
        if (field.required)
            checkValidation();
    }, [field]);

    const [state, setState] = useState<PropertyEditorState>({
        editorValue: field
    });

    const openEditor = () => setEditorOpen(true);

    const confirmEditor = (data?: any) => {
        if (data) {
            const editorData = JSON.parse(JSON.stringify(data));
            let editor = JSON.parse(JSON.stringify(state?.editorValue));
            if (isInteractionValue()) {
                editor.value = Object.values(editorData).map((item: any) => String(item.value));
            } else {
                editor.value = editorData;
            }

            setState({ editorValue: editor });
            onChange(field.id, editor.value);
        }
        closeEditor();
    }

    const closeEditor = () => setEditorOpen(false);

    const getSelectSolidFaceValue = () => {
        let faces: string[] = [];
        for (const face of Object.values<IFace>(state?.editorValue.value ?? [])) {
            if (face) {
                let faceAxis = Object.keys(face)?.shift();
                if (faceAxis !== undefined) {
                    let faceValue = Object.values(face)?.shift()?.value;
                    if (faceValue !== undefined) {
                        faces.push(FacesUtil.getFaceByAxisAndValue(faceAxis, faceValue));
                    }
                }
            }
        }
        return faces.map(m => m).join(', ');
    }

    const getOperationOrderValue = () => {
        const order = currentSettings?.machineSettings?.operationsStepsOrder;

        if (!state?.editorValue?.value) {
            state.editorValue.value = order;
        }

        let operationTypes = InteractionUtil.getInteractionOrderValue(state?.editorValue, field, formState, true);
        return operationTypes.map(m => translatorHelper.getTranslatedValue(m)).join(', ');
    }

    const getPrioritizationOrderValue = () => {
        const prioritizationOrder = currentSettings?.machineSettings?.prioritizationOrder;

        if (!state?.editorValue?.value) {
            state.editorValue.value = prioritizationOrder;
        }

        let operationTypes = InteractionUtil.getInteractionOrderValue(state.editorValue, field, formState, true);
        return operationTypes.map(m => translatorHelper.getTranslatedValue(m)).join(', ');
    }

    const getInsertionEnabledProcesses = () => {
        const insertionProcesses = getUser?.processes.filter(p => p.processType === "Insertion");
        const availableProcesses = currentSettings?.insertionSettings?.availableProcesses as string[];

        if (availableProcesses.length === 0) {
            return state?.editorValue?.value?.map((v: any) => {
                if (v.enablingProcessOptionsType !== 0)
                    return v.processOutput?.processName
            })
                .filter((v: any) => v !== undefined)
                .join(', ');
        }
        else {
            const processes: any[] = [];

            for (let process of insertionProcesses) {
                if (availableProcesses.includes(process.processId)) {
                    processes.push(process.processName);
                }
            }

            return processes.filter((v: any) => v !== undefined).join(', ');
        }
    }

    const getSlotEnabledProcesses = () => {
        const slotProcesses = getUser?.processes.filter(p => p.processType === "Slot");
        const availableProcesses = currentSettings?.slotSettings?.availableProcesses as string[];

        if (availableProcesses.length === 0) {
            return state?.editorValue?.value?.map((v: any) => {
                if (v.enablingProcessOptionsType !== 0)
                    return v.processOutput?.processName
            })
                .filter((v: any) => v !== undefined)
                .join(', ');
        }
        else {
            const processes: any[] = [];

            for (let process of slotProcesses) {
                if (availableProcesses.includes(process.processId)) {
                    processes.push(process.processName);
                }
            }

            return processes.filter((v: any) => v !== undefined).join(', ');
        }
    }

    const getMachiningEnabledProcesses = () => {
        const machiningProcesses = getUser?.processes.filter(p => p.processType === "Machining");
        const availableProcesses = currentSettings?.machiningSettings?.availableProcesses as string[];

        if (availableProcesses.length === 0) {
            return state?.editorValue?.value?.map((v: any) => {
                if (v.enablingProcessOptionsType !== 0)
                    return v.processOutput?.processName
            })
                .filter((v: any) => v !== undefined)
                .join(', ');
        }
        else {
            const processes: any[] = [];

            for (let process of machiningProcesses) {
                if (availableProcesses.includes(process.processId)) {
                    processes.push(process.processName);
                }
            }

            return processes.filter((v: any) => v !== undefined).join(', ');
        }
    }

    const getSelectSlotToolsValue = () => {
        const selectedTools = currentSettings?.toolSettings?.selectedToolOrProcessIDByOperationType as { [key: string]: object };
        const containsValue = Array.isArray(state?.editorValue?.value);
        if (containsValue || !selectedTools.hasOwnProperty("SlotDTO") || Object.keys(selectedTools["SlotDTO"]).length === 0) {
            return containsValue ? state?.editorValue?.value?.map((v: any) => { return v.name }).join(', ') : '';
        }
        else {
            const tools: any[] = [];
            const slotTools = selectedTools["SlotDTO"] as string[];

            for (let mill of currentSettings?.toolSettings?.mills as any[]) {
                if (slotTools.includes(mill.id)) {
                    tools.push(mill.name);
                }
            }

            for (let saw of currentSettings?.toolSettings?.saws as any[]) {
                if (slotTools.includes(saw.id)) {
                    tools.push(saw.name);
                }
            }

            return tools.filter((v: any) => v !== undefined).join(', ');
        }
    }

    const getSelectMachiningToolsValue = () => {
        const selectedTools = currentSettings?.toolSettings?.selectedToolOrProcessIDByOperationType as { [key: string]: object };
        const containsValue = Array.isArray(state?.editorValue?.value);
        if (containsValue || !selectedTools.hasOwnProperty("ParametricMachiningDTO") || Object.keys(selectedTools["ParametricMachiningDTO"]).length === 0) {
            return containsValue ? state?.editorValue?.value?.map((v: any) => { return v.name }).join(', ') : '';
        }
        else {
            const tools: any[] = [];
            const machiningsTools = selectedTools["ParametricMachiningDTO"] as string[];

            for (let mill of currentSettings?.toolSettings?.mills as any[]) {
                if (machiningsTools.includes(mill.id)) {
                    tools.push(mill.name);
                }
            }

            return tools.filter((v: any) => v !== undefined).join(', ');
        }
    }

    const getSelectOrtogonalMachiningToolsValue = () => {
        const selectedTools = currentSettings?.toolSettings?.selectedToolOrProcessIDByOperationType as { [key: string]: object };
        const containsValue = Array.isArray(state?.editorValue?.value);
        if (containsValue || !selectedTools.hasOwnProperty("OrtogonalMachiningDTO") || Object.keys(selectedTools["OrtogonalMachiningDTO"]).length === 0) {
            return containsValue ? state?.editorValue?.value?.map((v: any) => { return v.name }).join(', ') : '';
        }
        else {
            const tools: any[] = [];
            const slotTools = selectedTools["OrtogonalMachiningDTO"] as string[];

            for (let mill of currentSettings?.toolSettings?.mills as any[]) {
                if (slotTools.includes(mill.id)) {
                    tools.push(mill.name);
                }
            }

            return tools.filter((v: any) => v !== undefined).join(', ');
        }
    }

    const getSlotSelectToolsByProcessValue = () => {
        const slotProcessIDs = getUser.processes
            ?.filter((p: IProcessOutput) => p.processType === "Slot")
            .map((p: IProcessOutput) => p.processId) || [];

        const selectedTools = currentSettings?.toolSettings?.toolIDByProcessID || {};
        const filteredSelectedTools = Object.keys(selectedTools)
            .filter(key => slotProcessIDs.includes(key))
            .reduce((obj, key) => {
                obj[key] = selectedTools[key];
                return obj;
            }, {} as { [key: string]: string[] });

        const tools = (currentSettings?.toolSettings?.mills || [])
            .concat(currentSettings?.toolSettings?.saws || []);

        const toolNameMap = tools.reduce((map: { [key: string]: string }, tool: ITool) => {
            if (tool.id && tool.name) {
                map[tool.id] = tool.name;
            }
            return map;
        }, {} as { [key: string]: string });

        const result = Object.entries(filteredSelectedTools)
            .map(([processId, toolIDs]) => {

                const process = getUser.processes.find(p => p.processId === processId);
                const toolNames = Array.isArray(toolIDs)
                    ? toolIDs.map(toolID => toolNameMap[toolID]).filter(name => name).join(', ')
                    : '';

                return process?.processName ? `${process.processName}: ${toolNames}` : undefined;
            })
            .filter((v: any) => v !== undefined);

        return result.length > 0 ? result.join(', ') : '';
    }

    const getMachiningSelectToolsByProcessValue = () => {
        const machiningProcessIDs = getUser.processes
            ?.filter((p: IProcessOutput) => p.processType === "Machining")
            .map((p: IProcessOutput) => p.processId) || [];

        const selectedTools = currentSettings?.toolSettings?.toolIDByProcessID || {};
        const filteredSelectedTools = Object.keys(selectedTools)
            .filter(key => machiningProcessIDs.includes(key))
            .reduce((obj, key) => {
                obj[key] = selectedTools[key];
                return obj;
            }, {} as { [key: string]: string[] });

        const tools = currentSettings?.toolSettings?.mills || [];

        const toolNameMap = tools.reduce((map: { [key: string]: string }, tool: ITool) => {
            if (tool.id && tool.name) {
                map[tool.id] = tool.name;
            }
            return map;
        }, {} as { [key: string]: string });

        const result = Object.entries(filteredSelectedTools)
            .map(([processId, toolIDs]) => {

                const process = getUser.processes.find(p => p.processId === processId);
                const toolNames = Array.isArray(toolIDs)
                    ? toolIDs.map(toolID => toolNameMap[toolID]).filter(name => name).join(', ')
                    : '';

                return process?.processName ? `${process.processName}: ${toolNames}` : undefined;
            })
            .filter((v: any) => v !== undefined);

        return result.length > 0 ? result.join(', ') : '';
    }

    const getSelectToolsByToolByConditionRules = () => {
        if (Array.isArray(state?.editorValue?.value)) {
            return state?.editorValue?.value
                ?.filter((f: any) => f.conditionRule !== undefined)
                .map((rule: IDataByConditionRules) => {
                    const field = fieldConditions.find(condition => condition.Value === rule.conditionRule.field.replace(/{|}/g, ''))?.Name ?? '';
                    const operator = operatorCondition.find(condition => condition.Value === rule.conditionRule.operator)?.Name ?? '';
                    return `${field} ${operator} ${rule.conditionRule.value}: ${rule.tool?.name ?? ''}`;
                })
                .join(', ');
        } else {
            const selectedToolsByConditions = currentSettings?.toolSettings?.toolIDByConditionID || {};
            const conditionRules = currentSettings?.toolSettings?.conditions || {};

            const tools = Array.isArray(currentSettings?.toolSettings?.mills)
                ? currentSettings.toolSettings.mills
                : [];

            let current = conditionRules[0];
            let returnValue: string[] = [];
            while (current) {
                const field = fieldConditions.find(condition => condition.Value === current.field.replace(/{|}/g, ''))?.Name ?? '';
                const operator = operatorCondition.find(condition => condition.Value === current.operator)?.Name ?? '';
                const toolID = selectedToolsByConditions[current.id];
                const tool = tools?.find((t: any) => t.id === toolID);
                returnValue.push(`${field} ${operator} ${current.value}: ${tool?.name ?? ''}`);

                current = current.innerCondition;
            }
            return returnValue.join(', ');
        }
    };

    const getSelectLabelSettingsValue = () => {
        const selectedLabelSettingsID = isEmptyGuid(state?.editorValue?.value)
            ? currentSettings?.labelSettingsId
            : state?.editorValue?.value
            ?? currentSettings?.labelSettingsId;

        const labelSettings = getUser.labelSettings;

        if (labelSettings) {
            const selectedLabelSettings = labelSettings.filter(l => l.id === selectedLabelSettingsID);

            if (selectedLabelSettings.length !== 0) {
                return selectedLabelSettings[0]?.name;
            }

            return '';
        }
    }

    const getSelectLabelPrinterSettingsValue = () => {
        const selectedFullLabelSettingsID = isEmptyGuid(state?.editorValue?.value)
            ? currentSettings?.labelPrinterSettingsId
            : state?.editorValue?.value
            ?? currentSettings?.labelPrinterSettingsId;

        const labelPrinterSettings = getUser.labelPrinterSettings;

        if (labelPrinterSettings) {
            const selectedLabelSettings = labelPrinterSettings.filter(l => l.id === selectedFullLabelSettingsID);

            if (selectedLabelSettings.length !== 0) {
                return selectedLabelSettings[0]?.name;
            }

            return '';
        }
    }

    const isInteractionValue = () =>
        field.editorType === editorTypeEnum.PrioritizationOrder ||
        field.editorType === editorTypeEnum.OperationsOrder

    const customRequiredField = () => requiredFieldsByOtherConditions.includes(field.editorType);

    const getEditorValue = () => {
        switch (field.editorType) {
            case editorTypeEnum.OperationsOrder:
                return getOperationOrderValue();
            case editorTypeEnum.ToolsByConditionRules:
                return getSelectToolsByToolByConditionRules();
            case editorTypeEnum.PrioritizationOrder:
                return getPrioritizationOrderValue();
            case editorTypeEnum.SelectInsertionProcesses:
                return getInsertionEnabledProcesses();
            case editorTypeEnum.SelectSlotProcesses:
                return getSlotEnabledProcesses();
            case editorTypeEnum.SelectMachiningProcesses:
                return getMachiningEnabledProcesses();
            case editorTypeEnum.SelectSlotTools:
                return getSelectSlotToolsValue();
            case editorTypeEnum.SelectMachiningTools:
                return getSelectMachiningToolsValue();
            case editorTypeEnum.SelectOrtogonalMachiningTools:
                return getSelectOrtogonalMachiningToolsValue();
            case editorTypeEnum.SelectSlotToolsByProcesses:
                return getSlotSelectToolsByProcessValue();
            case editorTypeEnum.SelectMachiningToolsByProcesses:
                return getMachiningSelectToolsByProcessValue();
            case editorTypeEnum.SelectSolidFaces:
                return getSelectSolidFaceValue();
            case editorTypeEnum.ZeroPoint:
                return getSelectSolidFaceValue();
            case editorTypeEnum.ZeroPointByFace:
                return getSelectSolidFaceValue();
            case editorTypeEnum.LabelSettingsSelectedEditor:
                return getSelectLabelSettingsValue();
            case editorTypeEnum.FullLabelSettingsSelectedEditor:
                return getSelectLabelPrinterSettingsValue();

            default: return 'NEEDS IMPLEMENTATION';
        }
    }

    const checkValidation = () => {
        const validator = validatorRef.current && validatorRef.current.instance();
        if (validator) {
            const validation = validatorRef.current.instance().validate();
            dispatch(!validation.isValid && field.isVisible ? addValidation({ field: field, isValid: false }) : dispatch(removeValidation(field)));
        }
    }

    const validateTools = (validationField: any) => {
        if (validationField.value) {
            const isValid = validationField.value && toolRegex.test(validationField.value);
            if (field.isVisible) {
                dispatch(isValid ? removeValidation(field) : addValidation({ field, isValid: false }));
            }
            return isValid;
        }
        return true;
    };

    return (
        <React.Fragment>

            <div className='dx-field-label dx-field-editor-margin-top' hidden={field.isVisible !== undefined ? !field.isVisible : false}>
                {<div className='dx-field-label-left'>
                    <TextBox disabled={disableForm} name={field.id} value={getEditorValue()} readOnly={true}>
                        <Validator ref={validatorRef}>
                            {field.required && <RequiredRule type='required' message='' />}
                            {(!isInteractionValue() || customRequiredField()) && <CustomRule validationCallback={validateTools} message='' />}
                        </Validator>
                    </TextBox>
                </div>
                }
                <div className={''}>
                    <Button disabled={disableForm} width={40} height={40} icon={editIcon} onClick={openEditor} />
                </div>
            </div>
            <Editor show={isEditorOpen}
                data={state.editorValue}
                currentSettings={currentSettings}
                onClose={closeEditor}
                onButtonOk={confirmEditor}
                formState={formState}
                editorName={field.editorType}
                field={field} />
        </React.Fragment>
    )
}