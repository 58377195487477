import NumberBox from "devextreme-react/number-box";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import {
    Validator,
    RequiredRule,
    ValidatorRef
} from 'devextreme-react/validator';
import { addValidation, removeValidation } from 'store/validation.store';
import { useAppDispatch } from "store/store";
import Authorization from "components/security/authorization";
import { KeyDownEvent } from "devextreme/ui/number_box";
import { TreeViewRef } from "devextreme-react/cjs/tree-view";

interface PropertyNumberState {
    numberOption: number;
}

export default function PropertyNumber({ field, onChange, disableForm, treeViewRef }: any) {

    const [isAuthorizationOpen, setToggleAuthorization] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [state, setState] = useState<PropertyNumberState>({
        numberOption: +field.value
    });
    const dispatch = useAppDispatch();
    const validatorRef = useRef<ValidatorRef>(null);

    useEffect(() => {
        setState({ numberOption: +field.value });
        if (field.required)
            checkValidation();
    }, [field]);

    const handleCheckChange = (value: number) => {
        setState({ numberOption: value });
        onChange(field.id, value);
    };

    const checkValidation = () => {
        const validator = validatorRef.current && validatorRef.current.instance();
        if (validator) {
            const validation = validatorRef.current.instance().validate();
            if (!validation.isValid && field.isVisible)
                dispatch(addValidation({ field: field, isValid: false }))
            else
                dispatch(removeValidation(field))
        }
    }

    const handleCheckSecurity = (event: KeyDownEvent) => {
        if (!isAuthorized) {
            if (field.requireAuthorization) {
                event.event?.preventDefault();
                (treeViewRef.current as TreeViewRef)?.instance().unselectAll();
            }
            setToggleAuthorization(field.requireAuthorization);
        }
    };

    return (
        <NumberBox className="dx-field-label" disabled={disableForm} step={0}
            visible={field.isVisible !== undefined ? !!field.isVisible : true}
            name={field.id} value={state.numberOption} onValueChange={handleCheckChange} onKeyDown={handleCheckSecurity}>
            <Validator ref={validatorRef} >
                {field.required && <RequiredRule type='required' message="" />}
            </Validator>
            <Authorization show={isAuthorizationOpen} onClose={() => setToggleAuthorization(false)} onButtonOk={(isValid: boolean) => setIsAuthorized(isValid)} />
        </NumberBox>
    )
}