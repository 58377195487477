import './tools.scss';

import React, { useState, useEffect, useRef } from 'react';
import PropertyForm from '../../components/properties/property.form';
import { Category, Field } from '../../components/properties/property.data';
import { IMill } from '../../data/tools/toolData';
import { INavigation } from '../../data/navigation/navigationType';
import { useLocation } from 'react-router-dom';
import PageHeaderComponent from '../../components/pages/header/page.header.component';
import { ISettings, ISettingsInput } from '../../data/settings/settingsData';
import BindManager from '../../helpers/bindHelper';
import { store, useAppDispatch } from '../../store/store';
import { editSettings } from '../../store/settings.store';
import { eHeaderButtonVisibility } from '../../components/header/header-button/header.button.visibility.types';
import TabPanelComponent from '../../components/tab-panel/tab.panel.component';
import { useTranslator } from 'contexts/translator.context';
import { MillsTranslates } from 'translator/pages/mills.translate';
import { Utils } from 'utils/utils';
import { addEventFormChangeField } from 'store/form.change.store';
import { addStartedPage, editStartedPage } from 'store/page.store';
import { ChangesPropagationLevel } from 'data/form/formData';

const { Title } = MillsTranslates();

export const millSection = "Mill"

const headerButtonsVisibility: eHeaderButtonVisibility[] = [eHeaderButtonVisibility.ADD, eHeaderButtonVisibility.COPY, eHeaderButtonVisibility.DELETE]

const Mill: React.FC = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [fields, setFields] = useState<Field[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [mills, setMills] = useState<IMill[]>([]);
  const [allowBindAssign, setAllowBindAssign] = useState(true);
  const [copyIndex, setCopyIndex] = useState<number>(1);
  const currentSettings = useRef<ISettings>();
  const currentTool = useRef<IMill>();
  const translatorHelper = useTranslator();
  const bindManager = new BindManager();
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);

  useEffect(() => {
    currentSettings.current = undefined;
    currentTool.current = undefined;
    setAllowBindAssign(true);
    setFields([]);
    setCategories([]);
    setMills([]);

    const navigation = state.pageNavigation as INavigation;

    if (navigation !== null && navigation.sectionTemplate?.pageSection === millSection) {
      const formFields = JSON.parse(JSON.stringify(navigation.sectionTemplate));
      const translationPromise = translatorHelper.getTranslatedFieldsAndCategories(formFields.properties, formFields.categories);

      setFields(translationPromise.translationFields);
      setCategories(translationPromise.translationCategories);

      const getPageData = store.getState().pageDataState.pagesStarted.find(f => f.startedPageSection === millSection);
      if (!getPageData)
        dispatch(addStartedPage({ startedPageSection: millSection }));
    }
  }, [state.pageNavigation, dispatch, translatorHelper]);

  useEffect(() => {
    if (Array.isArray(mills) && mills.length > 0) {
      let getPageData = JSON.parse(JSON.stringify(store.getState().pageDataState.pagesStarted.find(f => f.startedPageSection === millSection)));
      if (getPageData && mills) {
        getPageData.idSetup = currentSettings?.current?.id;
        getPageData.unsavedData = JSON.parse(JSON.stringify(mills));
        dispatch(editStartedPage(getPageData));
      }
    }
  }, [mills, dispatch]);

  const getSelectedMill = () => {
    return mills?.find(f => f.selected === true);
  }

  const onAddNewMill = async () => {
    const uuid = Utils.generateGUID();
    let newTool: IMill = {
      id: uuid,
      name: uuid,
      selected: false
    };

    const settings = JSON.parse(JSON.stringify(currentSettings.current!));
    settings?.toolSettings.mills?.push(newTool);
    currentSettings.current! = settings;

    propagateChanges(currentSettings.current!);
    setMills(previousState => [...previousState, newTool]);
    dispatch(addEventFormChangeField());
  }

  const onDeleteMill = async () => {
    const selectedTool = getSelectedMill();
    let indexOf = mills.findIndex(x => x === selectedTool);
    if (selectedTool !== undefined) {
      let newtools = mills.filter(a => a.id !== selectedTool?.id);

      const settings = JSON.parse(JSON.stringify(currentSettings.current!));
      settings.toolSettings.mills?.splice(0, settings.toolSettings.mills.length);
      settings.toolSettings.mills?.push(...newtools);
      currentSettings.current! = settings;

      propagateChanges(currentSettings.current!);

      setMills(newtools);

      if (indexOf > 0)
        millSelected(newtools[indexOf - 1].id!);
      else
        millSelected(newtools[0].id!);
    }
  }

  const onDuplicateMill = async () => {
    let selectedTool = getSelectedMill();
    if (selectedTool !== undefined) {
      const uuid = Utils.generateGUID();
      let duplicateTool = JSON.parse(JSON.stringify(selectedTool));
      duplicateTool.name = `${duplicateTool.name}(${copyIndex})`;
      duplicateTool.id = uuid;
      duplicateTool.selected = false;

      const settings = JSON.parse(JSON.stringify(currentSettings.current!));
      settings.toolSettings.mills?.push(duplicateTool);
      currentSettings.current! = settings;

      setCopyIndex(copyIndex + 1);
      propagateChanges(currentSettings.current!);
      setMills(previousState => [...previousState, duplicateTool]);
    }
  }

  const onMillClicked = (toolID: string) => {
    if (mills.find(f => f.id === toolID)?.selected === false)
      millSelected(toolID, true);
  }

  const millSelected = (toolID: string, onTabChange?: boolean) => {
    let getTool = mills.find(f => f.id === toolID);

    if (getTool)
      currentTool.current = getTool;

    let updatedFields: Field[] = [];
    for (var field of fields) {
      if (onTabChange)
        field.isVisible = false;

      updatedFields.push({
        ...field,
      })
    }

    bindManager.assignBinds(updatedFields, getTool);

    setMills(prevState => {
      let newState = prevState?.map(obj => {
        if (obj.id === getTool?.id) {
          return {
            ...obj,
            selected: obj.selected === undefined ? true : !obj.selected
          };
        }
        return {
          ...obj,
          selected: false
        };
      });
      return newState;
    });

    setFields(updatedFields)
  }

  const onUpdateSettings = () => {
    return currentSettings.current!;
  }

  const onBindAssign = (settings?: ISettings) => {

    const getPageData = store.getState().pageDataState.pagesStarted.find(f => f.startedPageSection === millSection && f.idSettings === currentSettings?.current?.id);

    if (allowBindAssign) {
      currentSettings.current = settings;

      if (getPageData && Array.isArray(getPageData?.unsavedData) && getPageData.unsavedData.length > 0) {
        const unsavedData = JSON.parse(JSON.stringify(getPageData?.unsavedData as IMill[]));
        const settings = JSON.parse(JSON.stringify(currentSettings.current!));
        settings.toolSettings.mills = unsavedData;
        currentSettings.current! = settings;

        propagateChanges(currentSettings.current!)
        setMills(unsavedData);
      }
      else
        setMills(settings?.toolSettings?.mills!);

      setAllowBindAssign(false);
    }

    if (getSelectedMill() === undefined) {
      millSelected(mills![0]?.id!);
    }

    setPageLoaded(Array.isArray(mills) && mills.length > 0);
    return fields;
  }

  const onBindUpdate = (fieldValue?: any, obj?: Field, settings?: ISettings) => {

    if (!obj!.bind || fieldValue === undefined) return;

    let selectedMill = getSelectedMill();

    bindManager.setValue(fieldValue, obj!, selectedMill);

    setMills(prevState => {
      let newState = prevState?.map(obj => {
        if (obj.id === selectedMill?.id) {
          return {
            ...obj,
            value: fieldValue
          };
        }
        return {
          ...obj
        };
      });
      return newState;
    });

    const copySettings = JSON.parse(JSON.stringify(settings!));
    copySettings.toolSettings.mills = mills;
    currentSettings.current = copySettings;
    propagateChanges(currentSettings.current!);
  }

  function propagateChanges(sett: ISettings) {
    const storedSettings = store.getState().settingsDataState.settings ?? [];

    storedSettings.forEach(SS => {
      const settingsInput: ISettingsInput = {
        machineInfo: SS.machineInfo,
        settingsToUpdate: [],
        settingsToDelete: SS.settingsToDelete
      }

      if (SS.machineInfo.id === sett.machineInfoId) {
        SS.settingsToUpdate?.forEach(SU => {
          if (SU.machineSettings.id === sett.machineSettings.id) {
            const settings: ISettings = {
              ...SU,
              toolSettings: {
                ...SU.toolSettings,
                mills: [...sett.toolSettings?.mills!]
              }
            };
            settingsInput.settingsToUpdate?.push(settings);
          }
          else {
            settingsInput.settingsToUpdate?.push(SU);
          }

        });
      }

      dispatch(editSettings(settingsInput));
    })
  }

  return (
    <React.Fragment>
      <PageHeaderComponent pageData={mills} buttonHandlers={[
        [eHeaderButtonVisibility.ADD, onAddNewMill],
        [eHeaderButtonVisibility.DELETE, onDeleteMill],
        [eHeaderButtonVisibility.COPY, onDuplicateMill]]}
        headerButtonsVisibility={headerButtonsVisibility} title={Title} />
      <div className='page-body-layout'>
        <TabPanelComponent currentData={currentTool.current} pageLoaded={pageLoaded} data={mills} onTabChanged={(id: string) => onMillClicked(id)} />
        <PropertyForm changesPropagationLevel={ChangesPropagationLevel.MachineSettings} categories={categories} fields={fields} onUpdateSettings={onUpdateSettings} onBindAssign={onBindAssign} onBindUpdate={onBindUpdate} />
      </div>
    </React.Fragment>
  );

}

export default Mill;