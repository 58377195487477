import { Field } from "../property.data";

export class InteractionUtil {

    static getInteractionOrderValue(data: any, field: Field, formState: Field[], useValue: boolean): string[] {
        let operationTypesSet = new Set<any>();
        let editorValue = data?.value;

        const constants = data?.constants;
        const interactionIds = data?.interaction?.ids as { [key: string]: string };

        if (editorValue === null || editorValue === undefined) {
            editorValue = field?.value;
        }

        if (constants && interactionIds && formState) {
            constants.forEach((constant: any) => {
                if (constant) {
                    const isInInteraction = Object.values(interactionIds).includes(constant.value);
                    if (!isInInteraction) {
                        if (useValue) {
                            operationTypesSet.add(constant.value);
                        } else {
                            operationTypesSet.add(constant);
                        }
                    }
                }
            });

            Object.keys(interactionIds).forEach(interactionKey => {
                const interestedField = formState.find((f: Field) => f.id === interactionKey);
                if (interestedField && interestedField.value) {
                    const constantType = interactionIds[interactionKey];
                    const interestedConstantField = constants.find((f: any) => f.value === constantType);
                    if (interestedConstantField) {
                        if (useValue) {
                            operationTypesSet.add(interestedConstantField.value);
                        } else {
                            operationTypesSet.add(interestedConstantField);
                        }
                    }
                }
            });
        }

        let operationTypes: any[] = [];
        if (Array.isArray(editorValue)) {
            editorValue.forEach((value: any) => {
                if (operationTypesSet.has(value)) {
                    operationTypes.push(value);
                    operationTypesSet.delete(value);
                }
                else if (operationTypesSet.has(value.section)) {
                    operationTypes.push(value.section);
                    operationTypesSet.delete(value.section);
                }
            });
        }

        operationTypes.push(...Array.from(operationTypesSet));

        return operationTypes;
    }
}