import { configureStore } from "@reduxjs/toolkit";
import { MachineDataSlice } from "./machine.store";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { UserDataSlice } from "./user.store";
import { NavigationDataSlice } from "./navigation.store";
import { SettingsDataSlice } from "./settings.store";
import { LogedAccountInfoDataSlice } from "./loged.user.store";
import { SelectedElementSlice } from "./selected.store";
import { CallEventDataSlice } from "./event.store";
import { ValidationDataSlice } from "./validation.store";
import { FormChangeDataSlice } from "./form.change.store";
import { PageDataSlice } from "./page.store";

export const store = configureStore({
    reducer: {
        machineDataState: MachineDataSlice.reducer,
        navigationDataState: NavigationDataSlice.reducer,
        settingsDataState: SettingsDataSlice.reducer,
        userDataState: UserDataSlice.reducer,
        logedAccountInfoState: LogedAccountInfoDataSlice.reducer,
        selectedElementDataState: SelectedElementSlice.reducer,
        callEventDataState: CallEventDataSlice.reducer,
        validationDataState: ValidationDataSlice.reducer,
        formChangeDataState: FormChangeDataSlice.reducer,
        pageDataState: PageDataSlice.reducer
    }
})

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;