
import './property.css';

import React from 'react';

export default function PropertyLabel({ field }: any) {
    return (
        <div className='field-layout'>
            <label className="field-layout-text" hidden={field.isVisible !== undefined ? !field.isVisible : false}>{field.localizedName}</label>
        </div>
    )
}