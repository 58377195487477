import './property.css';

import React, { useEffect, useRef, useState } from "react";
import PropertyLabel from "./property.label";
import PropertyInput from "./property.input";
import { Field } from "./property.data";
import TreeView, { TreeViewRef } from 'devextreme-react/tree-view';
import minusIcon from '../../assets/images/icons/minus.svg'
import plusIcon from '../../assets/images/icons/plus.svg';

export default function PropertyFieldset({ fieldsetKey, category, formState, currentSettings, disableForm, handleChange }: any) {

    const [isHiddenGroup, setIsHiddenGroup] = useState<boolean>(false);
    const treeViewRef = useRef<TreeViewRef>(null);

    const groupTreeLayout = [{
        id: '1',
        name: category.groupName,
        isGroupHeader: true,
        expanded: true,
    }, {
        id: '1_1',
        categoryId: '1',
        isGroupHeader: false,
        expanded: false,
    }]

    useEffect(() => {
        setIsHiddenGroup(checkHiddenGroup());
    }, [formState])

    const [groupTree, setGroupTree] = useState(groupTreeLayout);

    const getFieldsByCategory = (categoryID: string) => {
        return Object.values(formState as Field[]).filter(f => { return f.categoryID === categoryID });
    }

    const checkHiddenGroup = () => {
        return getFieldsByCategory(category.id).every(field => field.isVisible === undefined || (field.isVisible != undefined && !field.isVisible));
    }

    const onKeyClicked = (id: string) => {
        setGroupTree(prevState => {
            let newState = prevState?.map(obj => {
                if (obj.id === id) {
                    return {
                        ...obj,
                        expanded: !obj.expanded,
                    };
                }
                return { ...obj }
            });
            return newState;
        });
    }

    const renderCustomItem = (item: any) => {
        return (
            (item.isGroupHeader == false ?
                <div>
                    {getFieldsByCategory(category.id).map((field, index) => (
                        <div key={index} className={field.isVisible != undefined && field.isVisible ? 'properties-layout' : 'properties-layout-hidden'}>
                            <PropertyLabel field={field} />
                            <PropertyInput treeViewRef={treeViewRef} formState={formState} field={field} disableForm={disableForm} currentSettings={currentSettings} onChange={handleChange} />
                        </div>
                    ))
                    }</div>
                :
                <div onClick={() => onKeyClicked('1')} className={isHiddenGroup ? 'properties-layout-hidden' : 'group-text'}> {item.name}</div>
            )
        );
    }

    return (
        <React.Fragment>
            <TreeView
                ref={treeViewRef}
                disabled={disableForm}
                className={isHiddenGroup ? 'properties-tree-view-hidden' : 'properties-tree-view'}
                items={groupTree}
                dataStructure="plain"
                displayExpr="name"
                expandedExpr="expanded"
                parentIdExpr="categoryId"
                keyExpr="id"
                expandIcon={plusIcon}
                collapseIcon={minusIcon}
                itemRender={renderCustomItem}
            />
        </React.Fragment>
    )
}