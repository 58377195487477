import './login.css';

import { SingleCard } from 'layouts';
import { Routes, Route, Navigate } from 'react-router-dom';
import icon from '../../assets/images/icons/right_arrow.svg';
import { useEffect, useState } from 'react';
import authService from '../../cyncly-login/authService';
import { LoginTranslates } from '../../translator/pages/login.translate';
import React from 'react';

const { Welcome, ConectNow } = LoginTranslates();

export default function Login() {

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        async function handleCallback() {
            if (window.location.pathname === '/callback' || window.location.pathname === '/login') {
                await authService.signinCallback();
                window.location.replace('/');
            }
        }

        const url = new URL(window.location.href);
        const hasParams = url.searchParams.toString().length > 0;
        if (hasParams && !isLoading) {
            setIsLoading(true);
            handleCallback()
        }
    }, [isLoading]);

    const login = async () => {
        await authService.login();
    };

    return (
        <Routes>
            <Route
                path='/login'
                element={
                    <SingleCard title={Welcome}>
                        {!isLoading && (
                            <button className='login-button' onClick={login}>
                                <div className='login-button-text'>{ConectNow}</div>
                                <img className='button-icon' alt='login' src={icon} />
                            </button>
                        )}
                        {isLoading && (
                            <div className='center-screen-spinner'>
                                <div className="border-gray-300 h-10 w-10 animate-spin rounded-full border-8 border-t-purple-600" />
                            </div>
                        )}
                    </SingleCard>
                }
            />
            <Route path='*' element={<Navigate to={'/login'} />} />
        </Routes>
    );
}