import React from 'react';
import { Button } from 'devextreme-react/button';

import moveRightIcon from '../../assets/images/icons/double_right.svg';
import moveRightIconGray from '../../assets/images/icons/double_right_gray.svg';
import removeIcon from '../../assets/images/icons/delete.svg';
import removeIconGray from '../../assets/images/icons/delete_gray.svg';
import upIcon from '../../assets/images/icons/upward.svg';
import upIconGray from '../../assets/images/icons/upward_gray.svg';
import downIcon from '../../assets/images/icons/downward.svg';
import downIconGray from '../../assets/images/icons/downward_gray.svg';
import preferencesIcon from '../../assets/images/icons/settings.svg';
import preferencesIconGray from '../../assets/images/icons/settings_gray.svg';
import collapseIcon from '../../assets/images/icons/simple_down.svg';
import collapseIconGray from '../../assets/images/icons/simple_down_gray.svg';
import expandIcon from '../../assets/images/icons/double_down.svg';
import expandIconGray from '../../assets/images/icons/double_down_gray.svg';
import pulldownIcon from '../../assets/images/icons/reset.svg';

type ButtonGroupProps = {
  onClickMoveRight: () => void;
  onClickRemove: () => void;
  onClickMoveUp: () => void;
  onClickMoveDown: () => void;
  onClickPreferences: () => void;
  onClickGroup: () => void;
  onClickUngroup: () => void;
  onClickRefresh: () => void;
  buttonMoveRightState: () => boolean;
  buttonRemoveState: () => boolean;
  buttonMoveUpState: () => boolean;
  buttonMoveDownState: () => boolean;
  buttonGroupState: () => boolean;
  buttonUngroupState: () => boolean;
};

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  onClickMoveRight,
  onClickRemove,
  onClickMoveUp,
  onClickMoveDown,
  onClickPreferences,
  onClickGroup,
  onClickUngroup,
  onClickRefresh,
  buttonMoveRightState,
  buttonRemoveState,
  buttonMoveUpState,
  buttonMoveDownState,
  buttonGroupState,
  buttonUngroupState,
}) => {
  return (
    <div className='button-grop-layout'>
      <Button icon={buttonMoveRightState() ? moveRightIconGray : moveRightIcon} onClick={onClickMoveRight} disabled={buttonMoveRightState()} />
      <Button icon={buttonRemoveState() ? removeIconGray : removeIcon} onClick={onClickRemove} disabled={buttonRemoveState()} />
      <Button icon={buttonMoveUpState() ? upIconGray : upIcon} onClick={onClickMoveUp} disabled={buttonMoveUpState()} />
      <Button icon={buttonMoveDownState() ? downIconGray : downIcon} onClick={onClickMoveDown} disabled={buttonMoveDownState()} />
      <Button icon={buttonRemoveState() ? preferencesIconGray : preferencesIcon} onClick={onClickPreferences} disabled={buttonRemoveState()} />
      <Button icon={buttonGroupState() ? collapseIconGray : collapseIcon} onClick={onClickGroup} disabled={buttonGroupState()} />
      <Button icon={buttonUngroupState() ? expandIconGray : expandIcon} onClick={onClickUngroup} disabled={buttonUngroupState()} />
      <Button icon={pulldownIcon} onClick={onClickRefresh} />
    </div>
  );
};

export default ButtonGroup;