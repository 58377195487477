import { useNotification } from "contexts/notification.context";

export default function useCopyAction() {

    const { setNotificationConfig } = useNotification();

    const copyAction = async (value: string) => {
        await navigator.clipboard.writeText(value);
        setNotificationConfig({
            isVisible: true,
            message: 'Copiado para área de transferência',
            type: 'success'
        });
    };

    return copyAction;
};