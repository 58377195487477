
import TranslatorHelper from '../../helpers/translatorHelper';
const translatorHelper = new TranslatorHelper();
export const SaveTranslates = () => {
    return {
        SaveSuccess: translatorHelper.getTranslatedValue('SaveSuccess'),
        SaveError: translatorHelper.getTranslatedValue('SaveError'),
        SaveExpiredToken: translatorHelper.getTranslatedValue('SaveExpiredToken'),
        SavingData: translatorHelper.getTranslatedValue('SavingData'),
        BadRequest: translatorHelper.getTranslatedValue('BadRequest'),
        Unauthorized: translatorHelper.getTranslatedValue('Unauthorized'),
        Forbidden: translatorHelper.getTranslatedValue('Forbidden'),
        NotFound: translatorHelper.getTranslatedValue('NotFound'),
        InternalServerError: translatorHelper.getTranslatedValue('InternalServerError'),
        ServiceUnavailable: translatorHelper.getTranslatedValue('ServiceUnavailable'),
        GatewayTimeout: translatorHelper.getTranslatedValue('GatewayTimeout'),
    };
};
