import { IMachineSettings } from "../../data/settings/settingsData";
import { IHandler } from "../handlerManager";

export class PrioritizationOrderHandler implements IHandler {
    canHandle(bind: string): boolean {
        return bind === "SettingsDTO.MachineSettings.PrioritizationOrder";
    }

    handleTo(ogObject: object, object: object, value: any, bind: string) {
        const machineSettings = object as IMachineSettings;
        if (machineSettings !== undefined) {
            if (machineSettings!.prioritizationOrder?.length !== 0) {
                return machineSettings!.prioritizationOrder;
            }
        }

        return value;
    }

    handleBack(ogObject: object, object: object, value: any, bind: string) {
        const machineSettings = object as IMachineSettings;
        if (machineSettings !== undefined) {
            machineSettings.prioritizationOrder = value;
        }

        return value;
    }
}