import React, { useEffect, useState } from "react";
import Modal from "../base/modal/modal";
import useModalHook from "../../hooks/modal.hook";
import { PropTypes } from 'data/modal/modal.proprieties';
import DataGrid, { Column, ColumnFixing, Editing, Lookup } from 'devextreme-react/data-grid';
import { store } from "../../store/store"
import { enablingSlotProcessOptions, IDataBySelectedProcess } from "./editor.interfaces";
import { enablingProcessOptionsType } from "./editor.enums";
import { DataChange } from "devextreme/common/grids";
import { getSelectedToolIDByProcessID } from "../properties/util/property.editor.util";
import { EditorsTranslates } from "../../translator/components/editors.translate";
import { Utils } from "utils/utils";

const { SlotProcessesTitle, Process, Operation } = EditorsTranslates();

export default function SelectedSlotProcesses({ show, data, currentSettings, onClose, onButtonOk }: PropTypes) {

    const [dataSource, setDataSource] = useState<IDataBySelectedProcess[]>(Object.assign([], data));
    const { isOpen, toggle, open } = useModalHook();

    const state = store.getState();
    const getUser = state.userDataState.user;

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        if (show) {
            populateDataSource();
            open();
        }

        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [data, show])

    const populateDataSource = () => {
        let loadByID = Utils.isGuidMap(data);
        if (loadByID) {
            let newData: IDataBySelectedProcess[] = [];
            const slotProcesses = getUser?.processes.filter(p => p.processType === "Slot");
            const availableProcesses = currentSettings?.slotSettings?.availableProcesses as string[];
            const processesIds = getSelectedToolIDByProcessID(currentSettings?.toolSettings!, "SlotDTO");

            for (let process of slotProcesses) {
                if (availableProcesses.includes(process.processId)) {
                    let defaultProcess: IDataBySelectedProcess = {
                        processOutput: process,
                        enablingProcessOptionsType:
                            processesIds.hasOwnProperty(process.processId)
                                ? enablingProcessOptionsType.EnabledWithSlotToolByProcess
                                : enablingProcessOptionsType.EnabledWithSlotTool
                    };
                    newData.push(defaultProcess);
                }
                else {
                    let defaultProcess: IDataBySelectedProcess = {
                        processOutput: process,
                        enablingProcessOptionsType: enablingProcessOptionsType.Disabled
                    };
                    newData.push(defaultProcess);
                }
            }
            setDataSource(newData);
        }
    }

    const onCellValueChanged = (record: DataChange[]) => {
        const updatedDataSource = dataSource.map(item => {
            if (item.processOutput.processId === record[0].key) {
                return {
                    ...item,
                    enablingProcessOptionsType: record[0].data.enablingProcessOptionsType
                };
            }
            return item;
        });
        setDataSource(updatedDataSource);
    }

    const saveData = () => onButtonOk(dataSource);

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} close={onClose} width="35%" height="55%" onButtonOk={saveData} modalHeader={SlotProcessesTitle}>
                <DataGrid
                    dataSource={dataSource}
                    keyExpr="processOutput.processId"
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={false}
                    showBorders={true}
                    height={windowSize.width > 1600 ? 400 : windowSize.width > 1180 ? 300 : 200}
                >
                    <Editing
                        mode="cell"
                        allowUpdating={true}
                        startEditAction={'click'}
                        onChangesChange={onCellValueChanged} />
                    <ColumnFixing enabled={true} />
                    <Column
                        alignment="center"
                        caption={Process}
                        dataField="processOutput.processName"
                        width={250}
                    />
                    <Column dataField="enablingProcessOptionsType" caption={Operation} alignment="center" >
                        <Lookup dataSource={enablingSlotProcessOptions} valueExpr="Value" displayExpr="Name" />
                    </Column>
                </DataGrid>
            </Modal>
        </React.Fragment>
    );
}