import './machine.scss';

import React, { useState, useEffect, useRef } from 'react';
import PropertyForm from '../../components/properties/property.form';
import { Category, Field } from '../../components/properties/property.data';
import { IMachine } from '../../data/machine/machineData';
import { useLocation } from 'react-router-dom';
import { INavigation } from '../../data/navigation/navigationType';
import { IMachineSettings, ISettings, ISettingsInput, ISettingsOutput } from '../../data/settings/settingsData';
import { eHeaderButtonVisibility } from '../../components/header/header-button/header.button.visibility.types';
import PageHeaderComponent from '../../components/pages/header/page.header.component';
import { useTranslator } from 'contexts/translator.context';
import { MachineTranslates } from 'translator/pages/machine.translate';
import { store, useAppDispatch } from 'store/store';
import { addMachine, removeMachine } from 'store/machine.store';
import { Utils } from 'utils/utils';
import { addEventTrigger, eCallEventType, eTriggerCRUDType } from 'store/event.store';
import CreateMachineModal from 'components/pages/machine/create.machine.modal';
import { editSettings } from 'store/settings.store';
import { addEventFormChangeField } from 'store/form.change.store';
import { getMachineInfoSettings } from 'api/manufacture';
import LoadingOverlay from 'components/overlay/load/loading.overlay';
import { ChangesPropagationLevel } from 'data/form/formData';

const { Title } = MachineTranslates();

export const machineSection = "Machine";

const headerButtonsVisibility: eHeaderButtonVisibility[] = [eHeaderButtonVisibility.COPY, eHeaderButtonVisibility.ADD, eHeaderButtonVisibility.DELETE/*, eHeaderButtonVisibility.ADVANCED_OPTIONS*/]

const Machine: React.FC = () => {
  const { state } = useLocation();
  const [fields, setFields] = useState<Field[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [machines, setMachines] = useState<IMachine[]>([]);
  const [machineSettings, setMachineSettings] = useState<IMachineSettings>();
  const [openNewMachineModal, setOpenNewMachineModal] = useState<boolean>(false);
  const currentSettings = useRef<ISettings>();
  const translatorHelper = useTranslator();
  const dispatch = useAppDispatch();
  const [copyIndex, setCopyIndex] = useState<number>(1);
  const [loadingPage, setLoadingPage] = useState<boolean>(false);

  useEffect(() => {
    currentSettings.current = undefined;
    setFields([])
    setCategories([])
    setMachines([])

    const navigation = state.pageNavigation as INavigation;

    if (navigation !== null && navigation.sectionTemplate?.pageSection === machineSection) {
      const formFields = JSON.parse(JSON.stringify(navigation.sectionTemplate));
      const translationPromise = translatorHelper.getTranslatedFieldsAndCategories(formFields.properties, formFields.categories);

      setFields(translationPromise.translationFields);
      // os Restrictions vao pra outra tela separada
      setCategories(translationPromise.translationCategories.filter(s => s.id !== 'Restrictions'));
      setMachineSettings(navigation?.settings?.machineSettings);
      setMachines(store.getState().machineDataState.machines);
    }
  }, [translatorHelper, state.pageNavigation]);

  useEffect(() => {
    const navigation = state.pageNavigation as INavigation;
    if (machineSettings && navigation.text !== machineSettings?.name) {
      dispatch(addEventTrigger({
        id: Utils.generateGUID(),
        text: machineSettings!.name,
        isTriggered: true,
        callEventType: eCallEventType.MACHINE,
        data: state.pageNavigation as INavigation,
        triggerCRUDType: eTriggerCRUDType.EDIT
      }));
    }
  }, [machineSettings, dispatch, state.pageNavigation]);

  const onAddNewMachine = async () => setOpenNewMachineModal(true);

  const onNewMachineButtonOK = async (machineName: string) => {
    setLoadingPage(true);
    let currentMachine = state.pageNavigation as INavigation;
    if (currentMachine.machineInfo) {
      const getDefaultSettings = await getMachineInfoSettings(
        store.getState().userDataState.user.accessToken,
        store.getState().userDataState.user.accountID,
        [{ ...currentMachine.machineInfo }]
      ) as ISettingsOutput[];


      const name = machineName ?? Utils.generateGUID();
      const id = Utils.generateGUID();
      const newMachine: IMachine = {
        id: id,
        name: name,
        selected: false,
      }

      dispatch(addMachine(newMachine));
      setMachines([...machines, newMachine]);
      createSettings(eTriggerCRUDType.ADD, id, name, getDefaultSettings[0].defaultSettings);
      dispatch(addEventFormChangeField());
      setLoadingPage(false);
    }
  }

  const onDeleteMachine = async () => {

    let currentMachine = state.pageNavigation as INavigation;

    dispatch(addEventTrigger({
      id: Utils.generateGUID(),
      isTriggered: true,
      callEventType: eCallEventType.MACHINE,
      data: currentMachine,
      triggerCRUDType: eTriggerCRUDType.DELETE
    }));

    const machineToRemove = machines.find(f => f.id === currentMachine.id);
    if (machineToRemove) {
      dispatch(removeMachine(machineToRemove));
      setMachines((prevMachines) =>
        prevMachines.filter((machine) => machine !== machineToRemove)
      );
      createSettings(eTriggerCRUDType.DELETE)
    }
  }

  const onDuplicateMachine = async () => {

    let currentMachine = state.pageNavigation as INavigation;
    const name = `${currentMachine.text}(${copyIndex})`;
    const id = Utils.generateGUID();
    const clonedMachine: IMachine = {
      id: id,
      name: name,
      selected: false
    }

    dispatch(addMachine(clonedMachine));
    setMachines([...machines, clonedMachine]);
    setCopyIndex(copyIndex + 1);
    createSettings(eTriggerCRUDType.COPY, id, name);
  }

  const createSettings = async (triggerCRUDType: eTriggerCRUDType, id?: string, name?: string, defaultSettings?: ISettings) => {

    const storedSettings = store.getState().settingsDataState.settings ?? [];

    if (storedSettings) {
      switch (triggerCRUDType) {
        case eTriggerCRUDType.ADD: {
          let newSettings = JSON.parse(JSON.stringify(defaultSettings)) as ISettings;
          newSettings.id = Utils.generateGUID();
          newSettings.name = "Setup";
          newSettings.machineSettings.id = id!;
          newSettings.machineSettings.name = name!;
          newSettings.isEnabled = true;

          const nav = JSON.parse(JSON.stringify(state.pageNavigation)) as INavigation;
          nav.settings = newSettings;

          propagateChanges(newSettings);

          dispatch(addEventTrigger({
            id: id!,
            text: name,
            isTriggered: true,
            callEventType: eCallEventType.MACHINE,
            data: nav,
            triggerCRUDType: triggerCRUDType
          }));
        }
          break;
        case eTriggerCRUDType.COPY: {
          let getSettings = JSON.parse(JSON.stringify(storedSettings[0].settingsToUpdate)) as ISettings[];
          for (let sett of getSettings) {
            let currentMachine = state.pageNavigation as INavigation;
            if (currentMachine.settings?.machineSettings?.id === sett.machineSettings.id) {
              sett.id = Utils.generateGUID();
              sett.machineSettings.id = id!;
              sett.machineSettings.name = name!;
              const nav = JSON.parse(JSON.stringify(state.pageNavigation)) as INavigation;
              nav.settings = sett;

              propagateChanges(sett);
              dispatch(addEventTrigger({
                id: id!,
                text: name,
                isTriggered: true,
                callEventType: eCallEventType.MACHINE,
                data: nav,
                triggerCRUDType: triggerCRUDType
              }));
            }
          }
        }
          break;
        case eTriggerCRUDType.DELETE: {
          let getSettings = JSON.parse(JSON.stringify(storedSettings[0].settingsToUpdate)) as ISettings[];
          for (let sett of getSettings) {
            let currentMachine = state.pageNavigation as INavigation;
            if (currentMachine.settings?.machineSettings?.id === sett.machineSettings.id) {
              propagateChanges(sett, true);
            }
          }
        }
          break;
      }
    }
  };

  function propagateChanges(sett: ISettings, deleteSettings?: boolean) {

    const storedSettings = store.getState().settingsDataState.settings ?? [];

    let settingsToRemove: ISettingsInput[] = [];
    const updatedSettings = storedSettings.map(SS => {
      if (SS.machineInfo.id === sett.machineInfoId) {
        if (deleteSettings) {
          settingsToRemove.push(SS);
          return {
            ...SS,
            settingsToDelete: [...(SS.settingsToDelete ?? []), sett],
            settingsToUpdate: [...(SS.settingsToUpdate?.filter(s => s.id !== sett.id) ?? [])]
          };
        } else
          return {
            ...SS,
            settingsToUpdate: [...(SS.settingsToUpdate ?? []), sett]
          };
      }
      return SS;
    });

    updatedSettings.forEach(SS => {
      const settingsInput: ISettingsInput = {
        machineInfo: SS.machineInfo,
        settingsToUpdate: SS.settingsToUpdate,
        settingsToDelete: SS.settingsToDelete
      };
      dispatch(editSettings(settingsInput));
    });
  }

  const onUpdateSettings = () => {
    return currentSettings.current!;
  }

  const onFieldChanged = (fieldName?: string, fieldValue?: any) => {
    if (fieldName === "MachineName") {
      let updateSettings = JSON.parse(JSON.stringify(machineSettings)) as IMachineSettings;
      updateSettings.name = fieldValue;
      setMachineSettings(updateSettings);
    }
  }

  const onClickAdvancedOptions = () => {
    setLoadingPage(true);
    setFields([])
    setCategories([])
    setMachines([])
    const navigation = state.pageNavigation as INavigation;

    if (navigation !== null && navigation.sectionTemplate?.pageSection === machineSection) {
      const formFields = JSON.parse(JSON.stringify(navigation.sectionTemplate));
      const translationPromise = translatorHelper.getTranslatedFieldsAndCategories(formFields.properties, formFields.categories);

      setFields(translationPromise.translationFields);
      // os Restrictions vao pra outra tela separada
      setCategories(translationPromise.translationCategories);
      setMachineSettings(navigation?.settings?.machineSettings);
      setMachines(store.getState().machineDataState.machines);
    }
    setLoadingPage(false);

  }

  return (
    <div>
      <React.Fragment>
        <LoadingOverlay isLoading={loadingPage} />
        <PageHeaderComponent pageData={machines} buttonHandlers={[
          [eHeaderButtonVisibility.ADD, onAddNewMachine],
          [eHeaderButtonVisibility.DELETE, onDeleteMachine],
          [eHeaderButtonVisibility.COPY, onDuplicateMachine],
          [eHeaderButtonVisibility.ADVANCED_OPTIONS, onClickAdvancedOptions]]}
          headerButtonsVisibility={headerButtonsVisibility} title={Title} />
        <div className='page-body-layout'>
          <PropertyForm changesPropagationLevel={ChangesPropagationLevel.MachineSettings}
            categories={categories} fields={fields} onUpdateSettings={onUpdateSettings} onFieldChanged={onFieldChanged} />

          <CreateMachineModal show={openNewMachineModal} onClose={() => setOpenNewMachineModal(false)} onButtonOk={onNewMachineButtonOK} />
        </div>
      </React.Fragment>
    </div>
  );
}

export default Machine;