import { Category, Field } from "components/properties/property.data";
import { ISettings } from "data/settings/settingsData";

export interface FormProps {
    changesPropagationLevel: ChangesPropagationLevel,
    categories: Category[];
    fields: Field[];
    name?: string,
    onFieldChanged?: (fieldName?: string, fieldValue?: any) => void
    onBindUpdate?: (fieldValue?: any, obj?: Field, settings?: ISettings) => void
    onUpdateSettings?: () => ISettings
    onBindAssign?: (settings?: ISettings) => Field[]
}

export enum ChangesPropagationLevel {
    None,
    MachineModel,
    MachineSettings
}