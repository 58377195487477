export enum eHeaderButtonVisibility {
    ENABLE_SETUPS,
    SAVE,
    COPY,
    ADD,
    DELETE,
    OPTIONS,
    MOVE_DOWN,
    MOVE_UP,
    ADVANCED_OPTIONS
}