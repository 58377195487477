import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { INavigation } from "../data/navigation/navigationType";

interface NavigationDataState {
    navigation: INavigation[]
}

const initialState: NavigationDataState = {
    navigation: []
}

export const NavigationDataSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        addNavigation: (state, action: PayloadAction<INavigation>) => {
            state.navigation.push(JSON.parse(JSON.stringify(action.payload)))
        },
        editNavigation: (state, action: PayloadAction<INavigation>) => {
            var index = state.navigation.findIndex(s => s.id === action.payload.id)
            if (index !== -1) {
                state.navigation[index] = action.payload
            } else {
                state.navigation.push(JSON.parse(JSON.stringify(action.payload)))
            }
        },
        removeNavigation: (state, action: PayloadAction<INavigation>) => {
            state.navigation = state.navigation.filter(obj => obj.id !== action.payload.id)
        }
    }
})

export default NavigationDataSlice.reducer;
export const { addNavigation, editNavigation, removeNavigation } = NavigationDataSlice.actions;