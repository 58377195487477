import { IProcessOutput } from "data/machine/templateData";
import { IMachineInfoModel, IMachineInfoParamsModel } from "../data/machine/machineData";
import { ISettingsInput, ISettingsOutput, IGetSettingsParamsModel, ISaveSettingsParamsModel, IGetprocessesParamsModel, IGetParamsModel } from "../data/settings/settingsData";
import { getApiAddress } from "./setupProxy";
import authService from "cyncly-login/authService";
import { APIResponseType } from "data/apiResponse/api.response.type";

export async function getManufactureMachineInfo(accessToken: string, accountID: number, pluginsID: string[]): Promise<IMachineInfoModel[]> {
    let machineInfoParamsModel: IMachineInfoParamsModel = {
        accessToken,
        accountID,
        pluginsID
    }

    try {
        const response = await fetch(`${getApiAddress()}/Manufacture/GetMachineInfos/GetManufactureMachineInfo`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(machineInfoParamsModel)
        })
        let plugins = await response.json();
        console.log('Azure plugins:');
        console.log(plugins);
        return plugins;
    }
    catch {
        return [];
    }
}

export async function getMachineInfoSettings(accessToken: string, accountID: number, machineInfos: IMachineInfoModel[]): Promise<ISettingsOutput[]> {

    let settingsParamsModel: IGetSettingsParamsModel = {
        machineInfos,
        accessToken,
        accountID
    }

    try {
        const response = await fetch(`${getApiAddress()}/Manufacture/GetSettings/GetUserMachinesSettings`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(settingsParamsModel)
        })
        let template = await response.json();
        console.log('Machine default settings and user settings:');
        console.log(template);
        return template;
    }
    catch {
        return [];
    }
}

export async function getLabelSettings(accessToken: string, accountID: number): Promise<ISettingsOutput[]> {

    let settingsParamsModel: IGetParamsModel = {
        accessToken,
        accountID
    }

    try {
        const response = await fetch(`${getApiAddress()}/Label/GetLabelSettings/GetUserLabelSettings`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(settingsParamsModel)
        })
        let template = await response.json();
        console.log('User label settings:');
        console.log(template);
        return template;
    }
    catch {
        return [];
    }
}


export async function getLabelPrinterSettings(accessToken: string, accountID: number): Promise<ISettingsOutput[]> {

    let settingsParamsModel: IGetParamsModel = {
        accessToken,
        accountID
    }

    try {
        const response = await fetch(`${getApiAddress()}/LabelPrinter/GetLabelPrinterSettings/GetUserLabelPrinterSettings`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(settingsParamsModel)
        })
        let template = await response.json();
        console.log('User label printer settings:');
        console.log(template);
        return template;
    }
    catch {
        return [];
    }
}

export async function getProcesses(accessToken: string, accountID: number, systemID: string): Promise<IProcessOutput[]> {

    let processesParamsModel: IGetprocessesParamsModel = {
        accessToken,
        accountID,
        systemID
    }

    try {
        const response = await fetch(`${getApiAddress()}/Manufacture/GetProcesses/GetManufactureProcesses`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(processesParamsModel)
        })
        let processes = await response.json();
        console.log('Saved processes:');
        console.log(processes);
        return processes;
    }
    catch {
        return [];
    }
}

export async function postSaveUserSettings(settingsInputs: ISettingsInput[]): Promise<number> {
    try {
        return authService.getUser().then((user) => {
            if (user?.access_token && !user?.expired) {
                return saveUserSettings(user?.access_token, user.profile["accountID"] as number, settingsInputs);
            }
            else
                return Number(APIResponseType.EXPIRED_TOKEN);
        });
    } catch (error) {
        console.error("Error in postSaveUserSettings:", error);
        return Number(APIResponseType.INTERNAL_SERVER_ERROR);
    }
}

async function saveUserSettings(accessToken: string, accountID: number, settingsInputs: ISettingsInput[]): Promise<number> {
    try {
        const settingsParamsModel: ISaveSettingsParamsModel = {
            settingsInputs,
            accessToken,
            accountID
        }

        console.log(`Saving settings...`);
        const response = await fetch(`${getApiAddress()}/Manufacture/SaveSettings/SaveUserMachinesSettings`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(settingsParamsModel)
        })

        if (response.status === 200) {
            let saveResult = await response.json();
            console.log(`Result: ${saveResult}`);
            console.log(saveResult);
        }

        return response.status;
    }
    catch {
        return Number(APIResponseType.INTERNAL_SERVER_ERROR);
    }
}